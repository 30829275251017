import React, { useState } from "react";
import CSVReader from "react-csv-reader";
import { useTable } from "react-table";
import ReactPaginate from "react-paginate";
import { db } from "./firebase"; // Asegúrate de que la ruta sea correcta
import { collection, doc, writeBatch } from "firebase/firestore";

const ITEMS_PER_PAGE = 100;
const MAX_CHAR_LENGTH = 100;

const ProductImport = () => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const handleFileLoad = (data) => {
    if (data.length > 0) {
      const headers = data[0].map((header, index) => ({
        Header: header,
        accessor: header,
        id: index,
      }));
      const rows = data.slice(1).map((row, rowIndex) => {
        const rowData = {};
        row.forEach((cell, index) => {
          rowData[data[0][index]] = truncateText(cell, MAX_CHAR_LENGTH);
        });
        return { ...rowData, id: rowIndex };
      });
      setColumns(headers);
      setData(rows);
    }
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const offset = currentPage * ITEMS_PER_PAGE;
  const currentData = data.slice(offset, offset + ITEMS_PER_PAGE);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: currentData,
    });

  const uploadDataToFirebase = async () => {
    const batch = writeBatch(db);

    data.forEach((row) => {
      const rowRef = doc(collection(db, "products")); // Cambia "products" por tu colección de Firebase
      batch.set(rowRef, row);
    });

    try {
      await batch.commit();
      alert("Datos subidos exitosamente a Firebase!");
    } catch (error) {
      console.error("Error subiendo los datos a Firebase: ", error);
      alert(
        "Hubo un error al subir los datos. Revisa la consola para más detalles."
      );
    }
  };

  return (
    <div>
      <h1>Importar y Visualizar Productos</h1>
      <CSVReader
        onFileLoaded={handleFileLoad}
        parserOptions={{ header: false }}
        inputStyle={{ color: "red" }}
      />
      <table
        {...getTableProps()}
        style={{ border: "solid 1px blue", marginTop: "20px" }}
      >
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, colIndex) => (
                <th
                  {...column.getHeaderProps()}
                  key={colIndex}
                  style={{ borderBottom: "solid 3px red", color: "black" }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={rowIndex}>
                {row.cells.map((cell, cellIndex) => (
                  <td
                    {...cell.getCellProps()}
                    key={cellIndex}
                    style={{ padding: "10px", border: "solid 1px gray" }}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <button
        onClick={uploadDataToFirebase}
        style={{
          marginTop: "20px",
          padding: "10px 20px",
          backgroundColor: "green",
          color: "white",
          border: "none",
          cursor: "pointer",
        }}
      >
        Subir Datos a Firebase
      </button>
      <ReactPaginate
        previousLabel={"previous"}
        nextLabel={"next"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(data.length / ITEMS_PER_PAGE)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
    </div>
  );
};

export default ProductImport;
