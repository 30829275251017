// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBMFesVhR5L6G79hR6nXiPoR5AamM55_Ks",
  authDomain: "bexway-98cca.firebaseapp.com",
  projectId: "bexway-98cca",
  storageBucket: "bexway-98cca.appspot.com",
  messagingSenderId: "125728036429",
  appId: "1:125728036429:web:bd9d3ec417d601ff2e6e04",
  measurementId: "G-TSB1XJ3XQW",
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage, app as default };
