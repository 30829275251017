import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress,
  CardMedia,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";
import {
  doc,
  setDoc,
  getDoc,
  addDoc,
  collection,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#3b93b8",
    },
    "&:hover fieldset": {
      borderColor: "#2c7a9b",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#3b93b8",
    },
  },
});

const containerStyle = {
  width: "100%",
  height: "400px",
};

const PropertyForm = ({ wayId, setWayId, onNext }) => {
  const { user } = useUserAuth();
  const [propertyType, setPropertyType] = useState("");
  const [address, setAddress] = useState({
    country: "",
    street: "",
    apartment: "",
    zone: "",
    zipCode: "",
    city: "",
    state: "",
  });
  const [location, setLocation] = useState({ lat: -3.745, lng: -38.523 });
  const [showExactLocation, setShowExactLocation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categories, setCategories] = useState([]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28",
  });

  useEffect(() => {
    const loadData = async () => {
      if (wayId) {
        const docRef = doc(db, "Ways", wayId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setPropertyType(data.propertyType || "");
          setAddress(data.address || {});
          setLocation(data.location || { lat: -3.745, lng: -38.523 });
          setShowExactLocation(data.showExactLocation || false);
        }
      }
    };
    loadData();
  }, [wayId]);

  useEffect(() => {
    const fetchCategories = async () => {
      const querySnapshot = await getDocs(collection(db, "Categories"));
      const categoriesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategories(categoriesData);
    };

    fetchCategories();
  }, []);

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const handleMapClick = (event) => {
    setLocation({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (!user || !user.uid) {
      console.error("User data is not fully loaded.");
      setIsSubmitting(false);
      return;
    }

    const propertyData = {
      propertyType,
      address,
      location,
      showExactLocation,
      fechaDeCreacion: serverTimestamp(),
      creatorUID: user.uid,
      finished: false,
    };

    try {
      let docRef;
      if (wayId) {
        docRef = doc(db, "Ways", wayId);
        await setDoc(docRef, propertyData, { merge: true });
      } else {
        docRef = await addDoc(collection(db, "Ways"), propertyData);
        setWayId(docRef.id);
      }
      console.log("Property data saved successfully");
      onNext();
    } catch (error) {
      console.error("Error saving property data:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Tipo de Propiedad</InputLabel>
            <Select
              value={propertyType}
              onChange={(e) => setPropertyType(e.target.value)}
              label="Tipo de Propiedad"
            >
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.name}>
                  <Box display="flex" alignItems="center">
                    <CardMedia
                      component="img"
                      src={category.iconUrl}
                      alt={category.name}
                      sx={{ width: 40, height: 40, mr: 2 }}
                    />
                    <Box>
                      <Typography>{category.description}</Typography>
                    </Box>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTextField
            fullWidth
            label="País o región"
            variant="outlined"
            required
            value={address.country}
            onChange={handleAddressChange}
            name="country"
            placeholder="Ingrese el país o región"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTextField
            fullWidth
            label="Dirección"
            variant="outlined"
            required
            value={address.street}
            onChange={handleAddressChange}
            name="street"
            placeholder="Ingrese la dirección"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTextField
            fullWidth
            label="Departamento, habitación, etc. (si corresponde)"
            variant="outlined"
            value={address.apartment}
            onChange={handleAddressChange}
            name="apartment"
            placeholder="Ingrese el departamento, habitación, etc."
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTextField
            fullWidth
            label="Zona (si corresponde)"
            variant="outlined"
            value={address.zone}
            onChange={handleAddressChange}
            name="zone"
            placeholder="Ingrese la zona"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTextField
            fullWidth
            label="Código postal"
            variant="outlined"
            required
            value={address.zipCode}
            onChange={handleAddressChange}
            name="zipCode"
            placeholder="Ingrese el código postal"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTextField
            fullWidth
            label="Ciudad / municipio"
            variant="outlined"
            required
            value={address.city}
            onChange={handleAddressChange}
            name="city"
            placeholder="Ingrese la ciudad o municipio"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTextField
            fullWidth
            label="Estado"
            variant="outlined"
            required
            value={address.state}
            onChange={handleAddressChange}
            name="state"
            placeholder="Ingrese el estado"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            Mostrar tu ubicación exacta
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            Indica claramente a los huéspedes dónde se encuentra tu alojamiento.
            Solo les facilitaremos tu dirección cuando su reservación esté
            confirmada. Más información
          </Typography>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Mostrar Ubicación Exacta</InputLabel>
            <Select
              value={showExactLocation}
              onChange={(e) => setShowExactLocation(e.target.value)}
              label="Mostrar Ubicación Exacta"
            >
              <MenuItem value={true}>Sí</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {isLoaded && (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={location}
              zoom={10}
              onClick={handleMapClick}
            >
              <Marker position={location} />
            </GoogleMap>
          )}
        </Grid>
        <Box sx={{ mt: 4, textAlign: "center", width: "100%" }}>
          <Button
            type="submit"
            variant="contained"
            size="large"
            disabled={isSubmitting}
            sx={{
              backgroundColor: "#3b93b8",
              color: "white",
              "&:hover": {
                backgroundColor: "#2c7a9b",
              },
            }}
          >
            {isSubmitting ? (
              <CircularProgress size={24} />
            ) : (
              "Guardar Propiedad"
            )}
          </Button>
        </Box>
      </Grid>
    </form>
  );
};

PropertyForm.propTypes = {
  wayId: PropTypes.string,
  setWayId: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default PropertyForm;
