import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  Avatar,
  CircularProgress,
  Card,
  CardContent,
  CardMedia,
  Grid,
} from "@mui/material";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";

const VerificationStatus = () => {
  const { uid } = useParams();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, "Users", uid), (doc) => {
      if (doc.exists()) {
        setUserData(doc.data());
      } else {
        console.log("No such document!");
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [uid]);

  if (loading) {
    return (
      <Container maxWidth="sm" sx={{ py: 5 }}>
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (!userData) {
    return (
      <Container maxWidth="sm" sx={{ py: 5 }}>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          No se encontraron datos de verificación para este usuario.
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 5 }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
        Estado de Verificación
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          border: "1px solid #ddd",
          borderRadius: 2,
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          p: 3,
        }}
      >
        <Avatar
          src={userData.profilePhotoUrl}
          alt={userData.fullName}
          sx={{ width: 100, height: 100, mb: 2 }}
        />
        <Typography variant="h6">{userData.fullName}</Typography>
        <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
          {userData.email}
        </Typography>
        <Typography variant="body1">
          Número de Teléfono: {userData.phoneNumber}
        </Typography>
        <Typography variant="body1">Estado: {userData.status}</Typography>
        <Typography variant="body1">
          Verificado: {userData.verified ? "Sí" : "No"}
        </Typography>
        <Box sx={{ mt: 3, width: "100%", textAlign: "center" }}>
          <Typography variant="body1" gutterBottom>
            Identificación Oficial:
          </Typography>
          <img
            src={userData.idPhotoUrl}
            alt="Identificación Oficial"
            style={{ width: "100%", borderRadius: "5%", cursor: "pointer" }}
          />
        </Box>
        <Box sx={{ mt: 3, width: "100%", textAlign: "center" }}>
          <Typography variant="body1" gutterBottom>
            Foto con Identificación:
          </Typography>
          <img
            src={userData.faceAndIdPhotoUrl}
            alt="Foto con Identificación"
            style={{ width: "100%", borderRadius: "5%", cursor: "pointer" }}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default VerificationStatus;
