import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Avatar,
  Grid,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";

const VerificationManager = () => {
  const [verifications, setVerifications] = useState([]);
  const [statusFilter, setStatusFilter] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVerifications = async () => {
      let q = collection(db, "Users");
      if (statusFilter !== "All") {
        q = query(q, where("status", "==", statusFilter));
      }

      const querySnapshot = await getDocs(q);
      const verificationsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVerifications(verificationsData);
    };

    fetchVerifications();
  }, [statusFilter]);

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredVerifications = verifications.filter((verification) =>
    verification.fullName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleUserClick = (uid) => {
    navigate(`/verification/${uid}`);
  };

  return (
    <Container maxWidth="md" sx={{ py: 5 }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
        Gestión de Verificaciones
      </Typography>
      <FormControl fullWidth sx={{ mb: 3 }}>
        <InputLabel id="status-filter-label">Filtrar por Estado</InputLabel>
        <Select
          labelId="status-filter-label"
          id="status-filter"
          value={statusFilter}
          label="Filtrar por Estado"
          onChange={handleStatusChange}
        >
          <MenuItem value="All">Todos</MenuItem>
          <MenuItem value="Pending">Pendiente</MenuItem>
          <MenuItem value="Approved">Aprobado</MenuItem>
          <MenuItem value="Rejected">Rechazado</MenuItem>
        </Select>
      </FormControl>
      <TextField
        fullWidth
        label="Buscar por Nombre"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder="Buscar por Nombre"
        sx={{ mb: 3 }}
      />
      <Grid container spacing={2}>
        {filteredVerifications.map((verification) => (
          <Grid item xs={12} key={verification.id}>
            <Box
              display="flex"
              alignItems="center"
              p={2}
              sx={{
                border: "1px solid #ddd",
                borderRadius: 2,
                boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                mb: 2,
                cursor: "pointer",
              }}
              onClick={() => handleUserClick(verification.id)}
            >
              <Avatar
                src={verification.profilePhotoUrl}
                alt={verification.fullName}
                sx={{ width: 56, height: 56, mr: 2 }}
              />
              <Box>
                <Typography variant="h6">{verification.fullName}</Typography>
                <Typography variant="body1">{verification.email}</Typography>
                <Typography variant="body2">
                  Estado: {verification.status}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default VerificationManager;
