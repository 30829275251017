import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Box,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";
import {
  collection,
  doc,
  query,
  where,
  getDocs,
  addDoc,
  getDoc,
} from "firebase/firestore";
import PropertyForm from "./PropertyForm";
import GuestInfoForm from "./GuestInfoForm";
import AmenitiesForm from "./AmenitiesForm";
import PhotosAndDetailsForm from "./PhotosAndDetailsForm";
import BookingPreferencesForm from "./BookingPreferencesForm";

const steps = [
  "Información de la Propiedad",
  "Información de Huéspedes",
  "Servicios",
  "Fotos y Detalles",
  "Precios y Publicación",
];

const EspacioForm = () => {
  const { user } = useUserAuth();
  const { uid } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [wayId, setWayId] = useState(uid || null);
  const [loading, setLoading] = useState(true);
  const [initialData, setInitialData] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchSpace = async () => {
      if (wayId) {
        try {
          const docRef = doc(db, "Ways", wayId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setInitialData(docSnap.data());
          } else {
            setWayId(null); // If the document does not exist, prepare to create a new one
          }
        } catch (error) {
          console.error("Error fetching space:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchSpace();
  }, [wayId]);

  const isStepOptional = (step) => {
    return step === 1 || step === 2;
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (skipped.has(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("No puedes saltar este paso.");
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Container maxWidth="md" sx={{ py: 5 }}>
      <Typography
        variant="h3"
        gutterBottom
        sx={{ fontWeight: "bold", color: "#3b93b8", mb: 4 }}
        align={isMobile ? "center" : "left"}
      >
        {wayId ? "Editar Espacio" : "Crear Espacio"}
      </Typography>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Stepper
            activeStep={activeStep}
            orientation={isMobile ? "vertical" : "horizontal"}
          >
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Opcional</Typography>
                );
              }
              if (skipped.has(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography
                  sx={{ mt: 2, mb: 1 }}
                  align={isMobile ? "center" : "left"}
                >
                  Todos los pasos completados - tu propiedad ha sido{" "}
                  {wayId ? "actualizada" : "creada"}
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Regresar</Button>
                </Box>
              </div>
            ) : (
              <div>
                {activeStep === 0 && (
                  <PropertyForm
                    wayId={wayId}
                    setWayId={setWayId}
                    initialData={initialData}
                    onNext={handleNext}
                  />
                )}
                {activeStep === 1 && (
                  <GuestInfoForm
                    wayId={wayId}
                    initialData={initialData}
                    onNext={handleNext}
                  />
                )}
                {activeStep === 2 && (
                  <AmenitiesForm
                    wayId={wayId}
                    initialData={initialData}
                    onNext={handleNext}
                  />
                )}
                {activeStep === 3 && (
                  <PhotosAndDetailsForm
                    wayId={wayId}
                    initialData={initialData}
                    onNext={handleNext}
                  />
                )}
                {activeStep === 4 && (
                  <BookingPreferencesForm
                    wayId={wayId}
                    initialData={initialData}
                    onNext={handleNext}
                  />
                )}
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Atrás
                  </Button>
                  {isStepOptional(activeStep) && (
                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                      Saltar
                    </Button>
                  )}
                  <Button onClick={handleNext}>
                    {activeStep === steps.length - 1
                      ? "Finalizar"
                      : "Siguiente"}
                  </Button>
                </Box>
              </div>
            )}
          </div>
        </>
      )}
    </Container>
  );
};

export default EspacioForm;
