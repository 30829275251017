import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { useUserAuth } from "../../context/userAuthContext";
import SpaceCard from "../Home/SpaceCard";

const Favorites = () => {
  const { user } = useUserAuth();
  const [spaces, setSpaces] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFavoriteSpaces = async () => {
      setLoading(true);
      try {
        const q = query(
          collection(db, "Ways"),
          where("likes", "array-contains", user.uid)
        );
        const querySnapshot = await getDocs(q);
        const spacesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSpaces(spacesData);
      } catch (error) {
        console.error("Error fetching favorite spaces: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFavoriteSpaces();
  }, [user.uid]);

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : spaces.length === 0 ? (
        <Box textAlign="center" mt={4}>
          <Typography variant="h6" color="text.secondary">
            No tienes publicaciones marcadas como favoritas.
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={3}>
          {spaces.map((space) => (
            <Grid item xs={12} sm={6} md={4} key={space.id}>
              <SpaceCard space={space} />
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default Favorites;
