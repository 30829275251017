import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles.css";
import Navbar from "./Navbar";
import Product from "./Components/Product/Product";
import SuccessPage from "./pages/SuccessPage";
import Home from "./Components/Home/Home";
import AddStore from "./Store/AddStore";
import Login from "./Components/Login";
import Signup from "./Components/Signup";
import ProductImport from "./ProductImport";
import UserDashboard from "./Components/Dashboard/UserDashboard";
import { UserAuthContextProvider } from "./context/userAuthContext";
import MobileNavbar from "./MobileNavbar";
import HostMode from "./Components/Host/HostMode";
import ChatDashboard from "./Components/Chat/ChatDashboard";
import MainHistory from "./Components/History/MainHistory";
import UserVerification from "./Components/Verifications/UserVerification";
import VerificationManager from "./Components/AdminDashboard/VerificationManager";
import Verification from "./Components/Verifications/Verification";
import Status from "./Components/Verifications/Status";
import WayCreator from "./Components/Way/WayCreator";
import EspacioForm from "./Components/Way/EspacioForm";
import SpaceDetails from "./Components/Space/SpaceDetails";
import SpaceManager from "./Components/Space/SpaceManager";
import MySpace from "./Components/Space/MySpace";
import BookingManager from "./Components/Space/BookingManager";
import CategoryUpload from "./Components/AdminDashboard/CategoryUpload";
import Favorites from "./Components/UserSettings/Favorites";

export default function App() {
  return (
    <UserAuthContextProvider>
      <Router>
        <div className="App">
          <Navbar />
          <Routes>
            <Route
              path="/verificationmanager"
              element={<VerificationManager />}
            />
            <Route path="/favorites" element={<Favorites />} />
            <Route path="/bookingmanager/:uid" element={<BookingManager />} />
            <Route path="/myspace/:uid" element={<MySpace />} />
            <Route path="/spacemanager" element={<SpaceManager />} />
            <Route path="/space/:uid" element={<SpaceDetails />} />
            <Route path="/WayCreator" element={<WayCreator />} />
            <Route path="/newSpace" element={<EspacioForm />} />
            <Route path="/verificationstatus/:uid" element={<Status />} />
            <Route path="/verification/:uid" element={<Verification />} />
            <Route path="/newhost" element={<UserVerification />} />
            <Route path="/plans" element={<HostMode />} />
            <Route path="/trips" element={<MainHistory />} />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="/import" element={<ProductImport />} />
            <Route path="/login" element={<Login />} />
            <Route path="/registro" element={<Signup />} />
            <Route path="/profile" element={<UserDashboard />} />
            <Route path="/addstore" element={<AddStore />} />
            <Route path="/messages" element={<ChatDashboard />} />
            <Route path="/" element={<Home />} />
            <Route path="/categoryupload" element={<CategoryUpload />} />

            <Route
              path="/carrito"
              element={
                <div>
                  <Product />
                </div>
              }
            />
          </Routes>
          <MobileNavbar />
        </div>
      </Router>
    </UserAuthContextProvider>
  );
}
