import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Popover,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#fff",
  borderRadius: 30,
  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  padding: theme.spacing(1),
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    padding: theme.spacing(2),
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
  flex: 1,
  marginBottom: theme.spacing(1),
  [theme.breakpoints.up("md")]: {
    marginBottom: 0,
    marginRight: theme.spacing(1),
  },
}));

const SearchButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#3e94b9",
  color: "#fff",
  borderRadius: 30,
  padding: theme.spacing(1, 3),
  "&:hover": {
    backgroundColor: "#2c7a9b",
  },
  width: "100%",
  [theme.breakpoints.up("md")]: {
    width: "auto",
  },
}));

const Buscador = ({ searchTerm, handleSearchChange, onSearch }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [guests, setGuests] = useState({ adults: 0, children: 0, infants: 0 });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGuestChange = (type, value) => {
    setGuests((prev) => ({ ...prev, [type]: Math.max(0, prev[type] + value) }));
  };

  const handleSearch = () => {
    onSearch(searchTerm, guests);
  };

  const open = Boolean(anchorEl);
  const id = open ? "guests-popover" : undefined;

  return (
    <StyledBox>
      <StyledTextField
        placeholder="Destino"
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LocationOnIcon />
            </InputAdornment>
          ),
        }}
      />
      {!isMobile && (
        <>
          <StyledTextField
            placeholder="Llegada"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarTodayIcon />
                </InputAdornment>
              ),
            }}
          />
          <StyledTextField
            placeholder="Salida"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarTodayIcon />
                </InputAdornment>
              ),
            }}
          />
        </>
      )}
      <StyledTextField
        placeholder="Huéspedes"
        onClick={handleClick}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          ),
          readOnly: true,
        }}
        value={`${guests.adults + guests.children} huéspedes, ${
          guests.infants
        } bebés`}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 2, width: 300 }}>
          <Typography variant="h6" gutterBottom>
            Huéspedes
          </Typography>
          <Grid container spacing={2}>
            {["adults", "children", "infants"].map((type) => (
              <Grid item xs={12} key={type}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography>
                    {type === "adults"
                      ? "Adultos"
                      : type === "children"
                      ? "Niños"
                      : "Bebés"}
                  </Typography>
                  <Box>
                    <IconButton onClick={() => handleGuestChange(type, -1)}>
                      <RemoveIcon />
                    </IconButton>
                    <Typography component="span">{guests[type]}</Typography>
                    <IconButton onClick={() => handleGuestChange(type, 1)}>
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Popover>
      <SearchButton
        variant="contained"
        endIcon={<SearchIcon />}
        onClick={handleSearch}
      >
        Buscar
      </SearchButton>
    </StyledBox>
  );
};

Buscador.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default Buscador;
