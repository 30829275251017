import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#3b93b8",
    },
    "&:hover fieldset": {
      borderColor: "#2c7a9b",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#3b93b8",
    },
  },
});

const GuestInfoForm = ({ wayId, onNext }) => {
  const { user } = useUserAuth();
  const [guests, setGuests] = useState("");
  const [bedrooms, setBedrooms] = useState("");
  const [beds, setBeds] = useState("");
  const [lockableRooms, setLockableRooms] = useState("");
  const [bathroomType, setBathroomType] = useState({
    privateAttached: 0,
    privateInAccommodation: 0,
    shared: 0,
  });
  const [whoElse, setWhoElse] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      if (wayId) {
        const docRef = doc(db, "Ways", wayId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setGuests(data.guests || "");
          setBedrooms(data.bedrooms || "");
          setBeds(data.beds || "");
          setLockableRooms(data.lockableRooms || "");
          setBathroomType(
            data.bathroomType || {
              privateAttached: 0,
              privateInAccommodation: 0,
              shared: 0,
            }
          );
          setWhoElse(data.whoElse || []);
        }
      }
    };
    loadData();
  }, [wayId]);

  const handleBathroomTypeChange = (e) => {
    const { name, value } = e.target;
    setBathroomType((prevType) => ({
      ...prevType,
      [name]: parseInt(value, 10),
    }));
  };

  const handleWhoElseChange = (e) => {
    const { value } = e.target;
    setWhoElse(typeof value === "string" ? value.split(",") : value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (!user || !user.uid) {
      console.error("User data is not fully loaded.");
      setIsSubmitting(false);
      return;
    }

    const guestInfoData = {
      guests,
      bedrooms,
      beds,
      lockableRooms,
      bathroomType,
      whoElse,
    };

    try {
      const docRef = doc(db, "Ways", wayId);
      await setDoc(docRef, guestInfoData, { merge: true });
      console.log("Guest info data saved successfully");
      onNext();
    } catch (error) {
      console.error("Error saving guest info data:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StyledTextField
            fullWidth
            label="Huéspedes"
            variant="outlined"
            required
            value={guests}
            onChange={(e) => setGuests(e.target.value)}
            name="guests"
            placeholder="¿Cuántas personas pueden quedarse aquí?"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledTextField
            fullWidth
            label="Recámaras"
            variant="outlined"
            required
            value={bedrooms}
            onChange={(e) => setBedrooms(e.target.value)}
            name="bedrooms"
            placeholder="Número de recámaras"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledTextField
            fullWidth
            label="Camas"
            variant="outlined"
            required
            value={beds}
            onChange={(e) => setBeds(e.target.value)}
            name="beds"
            placeholder="Número de camas"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>¿Todas las habitaciones tienen cerradura?</InputLabel>
            <Select
              value={lockableRooms}
              onChange={(e) => setLockableRooms(e.target.value)}
              label="¿Todas las habitaciones tienen cerradura?"
            >
              <MenuItem value="Sí">Sí</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">
            ¿Qué tipo de baños están disponibles para los huéspedes?
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledTextField
            fullWidth
            label="Privado y anexado"
            variant="outlined"
            value={bathroomType.privateAttached}
            onChange={handleBathroomTypeChange}
            name="privateAttached"
            placeholder="¿Cuántos?"
            type="number"
          />
          <Typography variant="body2">
            Está conectado a la habitación para huéspedes y nadie más lo usa.
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledTextField
            fullWidth
            label="Privado en el alojamiento"
            variant="outlined"
            value={bathroomType.privateInAccommodation}
            onChange={handleBathroomTypeChange}
            name="privateInAccommodation"
            placeholder="¿Cuántos?"
            type="number"
          />
          <Typography variant="body2">
            Es privado, pero se accede a través de una zona común, como un
            pasillo.
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledTextField
            fullWidth
            label="Uso compartido"
            variant="outlined"
            value={bathroomType.shared}
            onChange={handleBathroomTypeChange}
            name="shared"
            placeholder="¿Cuántos?"
            type="number"
          />
          <Typography variant="body2">
            Se comparte con otras personas.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">¿Quién más estará ahí?</Typography>
          <Typography variant="body2">
            Los huéspedes tienen que saber si habrá otras personas durante su
            estancia.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>¿Quién más estará ahí?</InputLabel>
            <Select
              multiple
              value={whoElse}
              onChange={handleWhoElseChange}
              renderValue={(selected) => selected.join(", ")}
              label="¿Quién más estará ahí?"
            >
              <MenuItem value="Yo">Yo</MenuItem>
              <MenuItem value="Mi familia">Mi familia</MenuItem>
              <MenuItem value="Otros huéspedes">Otros huéspedes</MenuItem>
              <MenuItem value="Compañeros de casa">Compañeros de casa</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Box sx={{ mt: 4, textAlign: "center", width: "100%" }}>
          <Button
            type="submit"
            variant="contained"
            size="large"
            disabled={isSubmitting}
            sx={{
              backgroundColor: "#3b93b8",
              color: "white",
              "&:hover": {
                backgroundColor: "#2c7a9b",
              },
            }}
          >
            {isSubmitting ? (
              <CircularProgress size={24} />
            ) : (
              "Guardar Información"
            )}
          </Button>
        </Box>
      </Grid>
    </form>
  );
};

GuestInfoForm.propTypes = {
  wayId: PropTypes.string.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default GuestInfoForm;
