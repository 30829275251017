import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  CircularProgress,
  Card,
  CardMedia,
  CardContent,
  IconButton,
  Button,
  Chip,
} from "@mui/material";
import { useUserAuth } from "../../context/userAuthContext"; // Importa el contexto de autenticación
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { styled } from "@mui/material/styles";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: 12,
  boxShadow: "0 4px 12px rgba(0,0,0,0.08)",
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-3px)",
    boxShadow: "0 8px 16px rgba(0,0,0,0.12)",
  },
}));

const StatusChip = styled(Chip)(({ theme }) => ({
  borderRadius: 8,
  backgroundColor: theme.palette.primary.main,
  color: "white",
  fontWeight: "bold",
}));

const SpaceManager = () => {
  const { user } = useUserAuth();
  const [spaces, setSpaces] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSpaces = async () => {
      if (user) {
        try {
          const q = query(
            collection(db, "Ways"),
            where("creatorUID", "==", user.uid)
          );
          const querySnapshot = await getDocs(q);
          const spacesData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSpaces(spacesData);
        } catch (error) {
          console.error("Error fetching spaces: ", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchSpaces();
  }, [user]);

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!spaces.length) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <Typography variant="h6" color="text.secondary">
          No has creado ninguna publicación.
        </Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Mis Publicaciones
      </Typography>
      <Grid container spacing={2}>
        {spaces.map((space) => (
          <Grid item xs={12} sm={6} md={4} key={space.id}>
            <StyledCard>
              <Link
                to={`/space/${space.id}`}
                style={{ textDecoration: "none" }}
              >
                {space.photos && space.photos.length > 0 ? (
                  <CardMedia
                    component="img"
                    height="150"
                    image={space.photos[0]}
                    alt={`Foto del espacio ${space.title}`}
                    sx={{ objectFit: "cover" }}
                  />
                ) : (
                  <CardMedia
                    component="img"
                    height="150"
                    image="https://via.placeholder.com/150"
                    alt="Imagen no disponible"
                    sx={{ objectFit: "cover" }}
                  />
                )}
              </Link>
              <CardContent sx={{ p: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  <Typography variant="h6" fontWeight="bold" noWrap>
                    {space.title}
                  </Typography>
                  <IconButton>
                    <FavoriteBorderIcon />
                  </IconButton>
                </Box>
                <Typography variant="body2" color="text.secondary" noWrap>
                  {space.city}, {space.state}, {space.country}
                </Typography>
                <Typography variant="body2" color="text.secondary" noWrap>
                  ${space.pricePerNight} / noche
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 1,
                  }}
                >
                  <StatusChip
                    label={space.finished ? "Verificado" : "Sin Verificar"}
                    sx={{
                      backgroundColor: space.finished ? "#4caf50" : "#f44336",
                    }}
                  />
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<EditIcon />}
                    component={Link}
                    to={`/myspace/${space.id}`}
                    sx={{ textTransform: "none", fontSize: "0.875rem" }}
                  >
                    Gestionar
                  </Button>
                </Box>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default SpaceManager;
