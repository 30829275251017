import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Grid,
  Button,
  Box,
  TextField,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import DeleteIcon from "@mui/icons-material/Delete";
import { storage, db } from "../../firebase";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#3b93b8",
  color: "white",
  "&:hover": {
    backgroundColor: "#2c7a9b",
  },
}));

const PhotosAndDetailsForm = ({ wayId, onNext }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [photos, setPhotos] = useState([]);
  const [photoPreviews, setPhotoPreviews] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchWayData = async () => {
      if (wayId) {
        const docRef = doc(db, "Ways", wayId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const wayData = docSnap.data();
          setTitle(wayData.title || "");
          setDescription(wayData.description || "");
          setPhotos(wayData.photos || []);
        }
      }
    };
    fetchWayData();
  }, [wayId]);

  const handlePhotoChange = (event) => {
    const files = Array.from(event.target.files);
    const newPhotoPreviews = files.map((file) => URL.createObjectURL(file));
    setPhotoPreviews((prevPreviews) => [...prevPreviews, ...newPhotoPreviews]);
  };

  const handleRemovePhoto = async (index) => {
    if (index < photos.length) {
      const photoUrl = photos[index];
      const fileName = decodeURIComponent(
        photoUrl.split("/o/")[1].split("?alt=media")[0]
      );
      const photoRef = ref(storage, fileName);
      try {
        await deleteObject(photoRef);
      } catch (error) {
        console.error("Error removing photo from storage: ", error);
      }
      setPhotos((prevPhotos) => prevPhotos.filter((_, i) => i !== index));
    } else {
      const localIndex = index - photos.length;
      setPhotoPreviews((prevPreviews) =>
        prevPreviews.filter((_, i) => i !== localIndex)
      );
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      const newPhotoUrls = await Promise.all(
        photoPreviews.map(async (preview, index) => {
          const response = await fetch(preview);
          const blob = await response.blob();
          const file = new File([blob], `photo${photos.length + index}.jpg`, {
            type: blob.type,
          });
          return await uploadToStorage(file, `ways/${wayId}`, file.name);
        })
      );

      const updatedPhotos = [...photos, ...newPhotoUrls];
      const docRef = doc(db, "Ways", wayId);
      await updateDoc(docRef, {
        title,
        description,
        photos: updatedPhotos,
        finished: true,
      });
      onNext();
    } catch (error) {
      console.error("Error updating document: ", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const uploadToStorage = async (file, folder, filename) => {
    const fileRef = ref(storage, `${folder}/${filename}`);
    await uploadBytes(fileRef, file);
    return getDownloadURL(fileRef);
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography variant="h5" gutterBottom>
        Agrega algunas fotos de tu alojamiento
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            component="label"
            startIcon={<PhotoCameraIcon />}
          >
            Subir Fotos
            <input
              type="file"
              hidden
              accept="image/*"
              multiple
              onChange={handlePhotoChange}
            />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {photos.map((photo, index) => (
              <Box key={index} sx={{ position: "relative", m: 1 }}>
                <img
                  src={photo}
                  alt={`Foto ${index + 1}`}
                  style={{ width: 100, height: 100, borderRadius: 5 }}
                />
                <IconButton
                  sx={{ position: "absolute", top: 0, right: 0 }}
                  onClick={() => handleRemovePhoto(index)}
                >
                  <DeleteIcon sx={{ color: "red" }} />
                </IconButton>
              </Box>
            ))}
            {photoPreviews.map((preview, index) => (
              <Box
                key={index + photos.length}
                sx={{ position: "relative", m: 1 }}
              >
                <img
                  src={preview}
                  alt={`Foto ${index + 1}`}
                  style={{ width: 100, height: 100, borderRadius: 5 }}
                />
                <IconButton
                  sx={{ position: "absolute", top: 0, right: 0 }}
                  onClick={() => handleRemovePhoto(index + photos.length)}
                >
                  <DeleteIcon sx={{ color: "red" }} />
                </IconButton>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Título"
            variant="outlined"
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Ponle un título a tu alojamiento"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Descripción"
            variant="outlined"
            required
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="¡Describe tu alojamiento que te hace especial!"
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ textAlign: "center" }}>
            <StyledButton
              type="submit"
              variant="contained"
              size="large"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={24} />
              ) : (
                "Guardar y Continuar"
              )}
            </StyledButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

PhotosAndDetailsForm.propTypes = {
  wayId: PropTypes.string.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default PhotosAndDetailsForm;
