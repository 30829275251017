import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Box, Typography, styled, IconButton } from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const StyledBox = styled(Box)(({ theme, selected }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(2),
  borderRadius: "5%",
  cursor: "pointer",
  transition: "all 0.3s ease",
  border: selected
    ? `1px solid ${theme.palette.grey[300]}`
    : "1px solid transparent",
  "&:hover": {
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  marginRight: theme.spacing(3),
}));

const StyledImage = styled("img")(({ selected }) => ({
  width: 40,
  height: 40,
  marginBottom: 4,
  opacity: selected ? 1 : 0.6,
}));

const StyledTypography = styled(Typography)(({ theme, selected }) => ({
  fontSize: "12px",
  color: selected ? theme.palette.text.primary : theme.palette.text.secondary,
  fontWeight: selected ? 600 : 400,
  textAlign: "center",
}));

const MenuBotones = ({ selectedCategory, handleCategoryChange }) => {
  const [categories, setCategories] = useState([]);
  const containerRef = useRef(null);

  useEffect(() => {
    const fetchCategoriesAndSpaces = async () => {
      const categoriesSnapshot = await getDocs(collection(db, "Categories"));
      const spacesSnapshot = await getDocs(collection(db, "Ways"));

      const spacesData = spacesSnapshot.docs.map((doc) => doc.data());
      const usedCategories = new Set(
        spacesData.map((space) => space.propertyType)
      );

      const categoriesData = categoriesSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((category) => usedCategories.has(category.name));

      setCategories(categoriesData);
    };

    fetchCategoriesAndSpaces();
  }, []);

  const scrollLeft = () => {
    containerRef.current.scrollBy({ left: -200, behavior: "smooth" });
  };

  const scrollRight = () => {
    containerRef.current.scrollBy({ left: 200, behavior: "smooth" });
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        mb: 4,
      }}
    >
      <IconButton
        onClick={scrollLeft}
        sx={{ position: "absolute", left: 0, zIndex: 1 }}
      >
        <ArrowBackIosIcon />
      </IconButton>
      <Box
        ref={containerRef}
        sx={{
          display: "flex",
          overflowX: "auto",
          "&::-webkit-scrollbar": { display: "none" },
          scrollbarWidth: "none",
          width: "100%",
          mx: 4,
        }}
      >
        {categories.map((category) => (
          <StyledBox
            key={category.id}
            selected={selectedCategory === category.id}
            onClick={() => handleCategoryChange(category.id)}
          >
            <StyledImage
              src={category.iconUrl}
              alt={category.name}
              selected={selectedCategory === category.id}
            />
            <StyledTypography selected={selectedCategory === category.id}>
              {category.description}
            </StyledTypography>
          </StyledBox>
        ))}
      </Box>
      <IconButton
        onClick={scrollRight}
        sx={{ position: "absolute", right: 0, zIndex: 1 }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};

MenuBotones.propTypes = {
  selectedCategory: PropTypes.string,
  handleCategoryChange: PropTypes.func.isRequired,
};

export default MenuBotones;
