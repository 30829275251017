import React, { useState, useEffect } from "react";
import { initMercadoPago, Wallet } from "@mercadopago/sdk-react";
import axios from "axios";

const Product = () => {
  const [preferenceId, setPreferenceId] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    initMercadoPago("APP_USR-301f452e-5347-49ff-94c6-264f14ca23d4"); // Utiliza tu Public Key de prueba
  }, []);

  const createPreference = async () => {
    try {
      const response = await axios.post(
        "https://api.mercadopago.com/checkout/preferences",
        {
          items: [
            {
              title: "Pollo",
              quantity: 1,
              currency_id: "MXN",
              unit_price: 15,
            },
          ],
          back_urls: {
            success: "https://www.tu-sitio/success",
            failure: "http://www.tu-sitio/failure",
            pending: "http://www.tu-sitio/pending",
          },
          auto_return: "approved",
        },
        {
          headers: {
            Authorization:
              "Bearer APP_USR-7068840882948296-071301-cf5a0c6069ab946899c5fd1a002e737a-175078923", // Utiliza tu Access Token de prueba
            "Content-Type": "application/json",
          },
        },
      );
      if (response.data && response.data.id) {
        setPreferenceId(response.data.id);
      } else {
        throw new Error("No se recibió ID de preferencia del servidor");
      }
    } catch (err) {
      console.error("Error creando la preferencia:", err);
      setError("Error al crear la preferencia de pago. Intente nuevamente.");
    }
  };

  return (
    <div className="card-product-container">
      <div className="card-product">
        <div className="card">
          <img
            src="https://seeklogo.com/images/P/pollo-feliz-logo-07B976E9BA-seeklogo.com.png"
            alt="Product Image"
          />
          <h3>Bananita contenta</h3>
          <p className="price">$15</p>
          <button onClick={createPreference}>Comprar</button>
          {error && <p className="error">{error}</p>}
          {preferenceId && (
            <Wallet
              initialization={{ preferenceId }}
              customization={{
                texts: {
                  valueProp: "smart_option",
                },
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Product;
