import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Alert,
  Box,
  Container,
  InputAdornment,
  IconButton,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system";
import { useUserAuth } from "../context/userAuthContext";
import GoogleIcon from "../assets/google-icon.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PhoneIcon from "@mui/icons-material/Phone";

const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "100vh",
  padding: theme.spacing(4),
  backgroundColor: "#F7F7F7",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: 400,
  padding: theme.spacing(4),
  backgroundColor: "white",
  borderRadius: 16,
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 8,
  padding: theme.spacing(1.5),
  fontWeight: 600,
  textTransform: "none",
  fontSize: 16,
}));

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const { logIn, googleSignIn } = useUserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await logIn(email, password);
      navigate("/");
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await googleSignIn();
      navigate("/");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <StyledContainer>
      <StyledBox>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          align="center"
          fontWeight="bold"
        >
          Iniciar Sesión
        </Typography>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <Box component="form" onSubmit={handleSubmit}>
          <StyledTextField
            fullWidth
            label="Correo Electrónico"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
          />
          <StyledTextField
            fullWidth
            label="Contraseña"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <StyledButton
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            sx={{ mb: 2 }}
          >
            Iniciar Sesión
          </StyledButton>
          <Divider sx={{ my: 2 }}>O</Divider>
          <StyledButton
            variant="outlined"
            onClick={handleGoogleSignIn}
            startIcon={<img src={GoogleIcon} alt="Google" width="20" />}
            fullWidth
            sx={{
              mb: 2,
              color: "black",
              borderColor: "black",
              "&:hover": {
                borderColor: "black",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            }}
          >
            Continuar con Google
          </StyledButton>
          <Link to="/phonesignup" style={{ textDecoration: "none" }}>
            <StyledButton
              variant="outlined"
              fullWidth
              startIcon={<PhoneIcon />}
              sx={{
                mb: 2,
                color: "black",
                borderColor: "black",
                "&:hover": {
                  borderColor: "black",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
            >
              Iniciar Sesión con Celular
            </StyledButton>
          </Link>
          <Typography variant="body2" align="center">
            ¿No tienes una cuenta?{" "}
            <Link
              to="/registro"
              style={{ textDecoration: "none", color: "#3b93b8" }}
            >
              ¡Crea una!
            </Link>
          </Typography>
        </Box>
      </StyledBox>
    </StyledContainer>
  );
};

export default Login;
