import React from "react";
import {
  Container,
  Box,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Chip,
  IconButton,
  InputBase,
  Paper,
  Badge,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

// Datos ficticios
const messages = [
  {
    id: 1,
    name: "Gustavo",
    message: "Actualización de Bexway: Cuéntanos qué te pareció tu estancia",
    date: "27-29 de jun.",
    location: "Mazunte",
    photo: "https://randomuser.me/api/portraits/men/1.jpg",
    timestamp: "Saturday",
    unread: true,
  },
  {
    id: 2,
    name: "Bea, Arturo",
    message:
      "Bea: Muy bien Pedro! Espero que puedas reservar lo más pronto posible, por que tenemos ...",
    date: "26-28 de abr.",
    location: "Guadalajara",
    photo: "https://randomuser.me/api/portraits/women/2.jpg",
    timestamp: "4/29",
    unread: false,
  },
  {
    id: 3,
    name: "Atención al cliente de Airbnb",
    message:
      "Esperamos que te haya ido excelente con la llegada a tu alojamiento en Guadalajara. Si nec...",
    date: "¡Hola!",
    location: "",
    photo: "https://randomuser.me/api/portraits/lego/1.jpg",
    timestamp: "4/26",
    unread: true,
  },
  // Agrega más mensajes aquí si lo deseas
];

const ChatDashboard = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography
        variant="h4"
        component="h1"
        fontWeight="bold"
        gutterBottom
        sx={{ mb: 4 }}
      >
        Mensajes
      </Typography>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Box display="flex" gap={1}>
          {["Todos", "Hospedar", "Huésped", "Asistencia"].map((label) => (
            <Chip
              key={label}
              label={label}
              clickable
              variant="outlined"
              sx={{
                borderColor: "#DDDDDD",
                "&:hover": {
                  borderColor: "#3b93b8",
                  backgroundColor: "#F7F7F7",
                },
                "&:focus": {
                  borderColor: "#3b93b8",
                  backgroundColor: "#F7F7F7",
                },
              }}
            />
          ))}
        </Box>
      </Box>

      <Paper
        component="form"
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 3,
          p: "2px 4px",
          border: "1px solid #DDDDDD",
          borderRadius: 2,
          boxShadow: "none",
        }}
      >
        <IconButton sx={{ p: "10px", color: "#3b93b8" }} aria-label="search">
          <SearchIcon />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Buscar mensajes"
          inputProps={{ "aria-label": "buscar mensajes" }}
        />
      </Paper>

      <List
        sx={{
          bgcolor: "background.paper",
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        {messages.map((msg, index) => (
          <React.Fragment key={msg.id}>
            <ListItem
              alignItems="flex-start"
              sx={{
                backgroundColor: msg.unread ? "#e3f2fd" : "inherit",
                "&:hover": { backgroundColor: "#F7F7F7" },
                cursor: "pointer",
                py: 2,
              }}
            >
              <ListItemAvatar>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                  invisible={!msg.unread}
                  sx={{
                    "& .MuiBadge-badge": {
                      backgroundColor: "#3b93b8",
                      color: "#3b93b8",
                    },
                  }}
                >
                  <Avatar alt={msg.name} src={msg.photo} />
                </Badge>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle1"
                    fontWeight={msg.unread ? "bold" : "regular"}
                  >
                    {msg.name}
                  </Typography>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{
                        display: "inline",
                        color: msg.unread ? "#000" : "text.secondary",
                      }}
                      component="span"
                      variant="body2"
                    >
                      {msg.message}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 1 }}
                    >
                      {msg.date} {msg.location}
                    </Typography>
                  </React.Fragment>
                }
              />
              <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ mb: 1 }}
                >
                  {msg.timestamp}
                </Typography>
                {msg.unread && (
                  <FiberManualRecordIcon
                    sx={{ color: "#3b93b8", fontSize: 12 }}
                  />
                )}
              </Box>
            </ListItem>
            {index < messages.length - 1 && (
              <Box sx={{ borderBottom: "1px solid #EEEEEE", width: "100%" }} />
            )}
          </React.Fragment>
        ))}
      </List>
    </Container>
  );
};

export default ChatDashboard;
