import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  Box,
  Tooltip,
  Avatar,
} from "@mui/material";
import {
  AccountCircle,
  Language,
  ExitToApp,
  SettingsApplications,
  HelpOutline,
  Login,
  PersonAdd,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useUserAuth } from "./context/userAuthContext"; // Importa el contexto de autenticación

const Navbar = () => {
  const { user, logOut } = useUserAuth(); // Obtén el usuario y la función de cierre de sesión del contexto de autenticación
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await logOut();
      console.log("Logout successful");
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };

  return (
    <AppBar
      position="sticky"
      color="inherit"
      elevation={0}
      style={{ zIndex: 1100, borderBottom: "1px solid #e0e0e0" }} // Añadir la línea gris clara
    >
      <Toolbar
        style={{
          backgroundColor: "white",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Link
          to="/"
          style={{
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/bexway-98cca.appspot.com/o/Logos%2FLogoBexway.png?alt=media&token=f46fb55d-274b-45a4-a490-34309eb576b1"
            alt="Logo"
            style={{ height: "50px", cursor: "pointer" }}
          />
        </Link>

        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <Tooltip title="Cambiar Idioma">
            <IconButton>
              <Language />
            </IconButton>
          </Tooltip>
          <Tooltip title="Menú">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                sx={{ width: 32, height: 32 }}
                src={user?.photoURL || ""}
                alt={user?.displayName || "User"}
              >
                {user?.displayName?.[0] || "B"}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {!user && (
              <>
                <MenuItem component={Link} to="/login">
                  <ListItemIcon>
                    <Login fontSize="small" />
                  </ListItemIcon>
                  Iniciar Sesión
                </MenuItem>
                <MenuItem component={Link} to="/registro">
                  <ListItemIcon>
                    <PersonAdd fontSize="small" />
                  </ListItemIcon>
                  Registrarse
                </MenuItem>
                <Divider />
              </>
            )}
            {user && (
              <>
                <MenuItem component={Link} to="/profile">
                  <ListItemIcon>
                    <AccountCircle fontSize="small" />
                  </ListItemIcon>
                  Perfil
                </MenuItem>
                <Divider />
                <MenuItem component={Link} to="/settings">
                  <ListItemIcon>
                    <SettingsApplications fontSize="small" />
                  </ListItemIcon>
                  Configuración
                </MenuItem>
                <MenuItem component={Link} to="/help">
                  <ListItemIcon>
                    <HelpOutline fontSize="small" />
                  </ListItemIcon>
                  Ayuda
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <ExitToApp fontSize="small" />
                  </ListItemIcon>
                  Cerrar Sesión
                </MenuItem>
              </>
            )}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
