import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Box,
  CardMedia,
  IconButton,
  CircularProgress,
  Avatar,
  Button,
  Chip,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import Slider from "react-slick";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import VerifiedIcon from "@mui/icons-material/Verified";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Iconos adicionales
import BedIcon from "@mui/icons-material/Bed";
import PeopleIcon from "@mui/icons-material/People";
import BathtubIcon from "@mui/icons-material/Bathtub";
import WifiIcon from "@mui/icons-material/Wifi";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import TvIcon from "@mui/icons-material/Tv";
import KitchenIcon from "@mui/icons-material/Kitchen";
import PoolIcon from "@mui/icons-material/Pool";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import SecurityIcon from "@mui/icons-material/Security";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StarIcon from "@mui/icons-material/Star";
import SmokeFreeIcon from "@mui/icons-material/SmokeFree";
import PetsIcon from "@mui/icons-material/Pets";
import FireExtinguisherIcon from "@mui/icons-material/FireExtinguisher";

import Calendar from "./Calendar"; // Importa el componente Calendar

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "white",
  borderRadius: "8px",
  padding: "10px 20px",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  borderRadius: "16px",
}));

const containerStyle = {
  width: "100%",
  height: "400px",
};

const SpaceDetails = () => {
  const { uid } = useParams();
  const [space, setSpace] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchSpace = async () => {
      try {
        const docRef = doc(db, "Ways", uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setSpace(docSnap.data());
        }
      } catch (error) {
        console.error("Error fetching space:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSpace();
  }, [uid]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <ArrowForwardIosIcon />,
    prevArrow: <ArrowBackIosNewIcon />,
  };

  const handleFavoriteClick = () => {
    setIsFavorite(!isFavorite);
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28",
  });

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!space) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <Typography variant="h6" color="text.secondary">
          Espacio no encontrado.
        </Typography>
      </Box>
    );
  }

  const renderAmenityIcon = (amenity) => {
    switch (amenity.toLowerCase()) {
      case "wifi":
        return <WifiIcon />;
      case "estacionamiento":
        return <LocalParkingIcon />;
      case "aire acondicionado":
        return <AcUnitIcon />;
      case "tv":
        return <TvIcon />;
      case "cocina":
        return <KitchenIcon />;
      case "piscina":
        return <PoolIcon />;
      case "gimnasio":
        return <FitnessCenterIcon />;
      case "no fumar":
        return <SmokeFreeIcon />;
      case "se admiten mascotas":
        return <PetsIcon />;
      case "extintor de incendios":
        return <FireExtinguisherIcon />;
      default:
        return null;
    }
  };

  const renderBathroomInfo = () => {
    if (space.bathroomType) {
      const {
        privateAttached = 0,
        privateInAccommodation = 0,
        shared = 0,
      } = space.bathroomType;

      const totalBathrooms = privateAttached + privateInAccommodation + shared;
      return <Typography variant="body1">{totalBathrooms} baños</Typography>;
    }
    return null;
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h5" fontWeight="bold" gutterBottom>
        {space.title}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <StarIcon sx={{ color: theme.palette.warning.main, mr: 1 }} />
        <Typography variant="body1" fontWeight="bold" mr={1}>
          4.9
        </Typography>
        <Typography variant="body2" color="text.secondary" mr={1}>
          (128 reseñas)
        </Typography>
        <VerifiedIcon sx={{ color: theme.palette.primary.main, mr: 1 }} />
        <Typography variant="body2" fontWeight="bold">
          Superhost
        </Typography>
        <Typography variant="body2" color="text.secondary" ml={1}>
          · {space.propertyType}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Slider {...settings}>
            {space.photos.slice(0, 5).map((photo, index) => (
              <CardMedia
                key={index}
                component="img"
                height={isMobile ? "300" : "500"}
                image={photo}
                alt={`Foto ${index + 1}`}
                sx={{ borderRadius: "16px" }}
              />
            ))}
          </Slider>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Box>
              {space.pricePerNight && (
                <Typography variant="h5" fontWeight="bold" gutterBottom>
                  ${space.pricePerNight} / noche
                </Typography>
              )}
              <Box
                sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
              >
                <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
                  <PeopleIcon sx={{ mr: 1 }} />
                  <Typography variant="body1">
                    {space.guests} huéspedes
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
                  <BedIcon sx={{ mr: 1 }} />
                  <Typography variant="body1">
                    {space.bedrooms} habitaciones
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <BathtubIcon sx={{ mr: 1 }} />
                  {renderBathroomInfo()}
                </Box>
              </Box>
            </Box>
            <IconButton onClick={handleFavoriteClick}>
              {isFavorite ? (
                <FavoriteIcon color="error" />
              ) : (
                <FavoriteBorderIcon />
              )}
            </IconButton>
          </Box>
          {/* Añadimos el componente Calendar para seleccionar las fechas */}
          <Box sx={{ my: 2 }}>
            <Calendar />
          </Box>
          {space.description && (
            <Typography variant="body1" paragraph>
              {space.description}
            </Typography>
          )}
          <Divider sx={{ my: 2 }} />
          {space.amenities && space.amenities.length > 0 && (
            <>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Servicios
              </Typography>
              <Grid container spacing={1}>
                {space.amenities.map((amenity, index) => (
                  <Grid item key={index}>
                    <StyledChip
                      icon={renderAmenityIcon(amenity)}
                      label={amenity}
                      variant="outlined"
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          {space.specialAmenities && space.specialAmenities.length > 0 && (
            <>
              <Typography
                variant="h6"
                fontWeight="bold"
                gutterBottom
                sx={{ mt: 2 }}
              >
                Amenidades Especiales
              </Typography>
              <Grid container spacing={1}>
                {space.specialAmenities.map((amenity, index) => (
                  <Grid item key={index}>
                    <StyledChip label={amenity} variant="outlined" />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          {space.safetyItems && space.safetyItems.length > 0 && (
            <>
              <Typography
                variant="h6"
                fontWeight="bold"
                gutterBottom
                sx={{ mt: 2 }}
              >
                Elementos de Seguridad
              </Typography>
              <Grid container spacing={1}>
                {space.safetyItems.map((item, index) => (
                  <Grid item key={index}>
                    <StyledChip
                      icon={<SecurityIcon />}
                      label={item}
                      variant="outlined"
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          <Divider sx={{ my: 2 }} />
          {space.whoElse && space.whoElse.length > 0 && (
            <>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Quién más estará ahí
              </Typography>
              <Grid container spacing={1}>
                {space.whoElse.map((person, index) => (
                  <Grid item key={index}>
                    <StyledChip
                      icon={<PeopleIcon />}
                      label={person}
                      variant="outlined"
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          <Divider sx={{ my: 2 }} />
          {isLoaded && space.location && (
            <>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Ubicación
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <LocationOnIcon sx={{ mr: 1 }} />
                <Typography variant="body1">
                  {space.location.address || "Dirección no disponible"}
                </Typography>
              </Box>
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={{ lat: space.location.lat, lng: space.location.lng }}
                zoom={15}
              >
                <Marker
                  position={{
                    lat: space.location.lat,
                    lng: space.location.lng,
                  }}
                />
              </GoogleMap>
            </>
          )}
        </Grid>
      </Grid>
      <Box sx={{ mt: 4 }}>
        <StyledButton
          variant="contained"
          fullWidth
          startIcon={<AccessTimeIcon />}
        >
          Reservar ahora
        </StyledButton>
      </Box>
    </Container>
  );
};

export default SpaceDetails;
