import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Buscador from "./Buscador";
import MenuBotones from "./MenuBotones";
import SpaceCard from "./SpaceCard";

const Home = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [spaces, setSpaces] = useState([]);
  const [filteredSpaces, setFilteredSpaces] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchSpaces = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, "Ways"));
        const spacesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSpaces(spacesData.filter((space) => space.finished !== false));
        setFilteredSpaces(
          spacesData.filter((space) => space.finished !== false)
        );
      } catch (error) {
        console.error("Error fetching spaces: ", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCategories = async () => {
      const querySnapshot = await getDocs(collection(db, "Categories"));
      const categoriesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategories(categoriesData);
    };

    fetchSpaces();
    fetchCategories();
  }, []);

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = spaces.filter((space) =>
      (space.title || "").toLowerCase().includes(value)
    );
    setFilteredSpaces(filtered);
  };

  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
    const category = categories.find((cat) => cat.id === categoryId);
    if (category) {
      const filtered = spaces.filter(
        (space) => space.propertyType === category.name
      );
      setFilteredSpaces(filtered);
    } else {
      setFilteredSpaces(spaces);
    }
  };

  const handleSearch = (term, guests) => {
    const filtered = spaces.filter(
      (space) =>
        (space.title || "").toLowerCase().includes(term) &&
        space.guests >= guests.adults + guests.children
    );
    setFilteredSpaces(filtered);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box mb={4}>
        <Buscador
          searchTerm={searchTerm}
          handleSearchChange={handleSearchChange}
          onSearch={handleSearch}
        />
      </Box>

      <MenuBotones
        selectedCategory={selectedCategory}
        handleCategoryChange={handleCategoryChange}
      />

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3}>
          {filteredSpaces.map((space) => (
            <Grid item xs={12} sm={6} md={4} key={space.id}>
              <SpaceCard space={space} />
            </Grid>
          ))}
        </Grid>
      )}

      {filteredSpaces.length === 0 && !loading && (
        <Box textAlign="center" mt={4}>
          <Typography variant="h6" color="text.secondary">
            No se encontraron espacios. Intenta con otra búsqueda.
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default Home;
