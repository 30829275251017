import React from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Avatar,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
} from "@mui/material";
import { Search, Place } from "@mui/icons-material";

// Datos ficticios de viajes anteriores
const pastTrips = [
  {
    id: 1,
    location: "Mazunte",
    host: "Gustavo",
    date: "27 de jun. de 2024 – 29 de jun. de 2024",
    photo: "https://randomuser.me/api/portraits/men/3.jpg",
    placePhoto:
      "https://media.traveler.es/photos/6137722d3decec3303bac18e/16:9/w_2000,h_1125,c_limit/123375.jpg",
  },
  {
    id: 2,
    location: "Guadalajara",
    host: "Bea",
    date: "26 de abr. de 2024 – 28 de abr. de 2024",
    photo: "https://randomuser.me/api/portraits/women/2.jpg",
    placePhoto:
      "https://toursgdl.com/wp-content/uploads/2019/06/a-close-up-of-a-yellow-building-description-autom.jpeg.webp",
  },
  {
    id: 3,
    location: "Sayulita",
    host: "Pau",
    date: "25 de mar. de 2024 – 27 de mar. de 2024",
    photo: "https://randomuser.me/api/portraits/men/3.jpg",
    placePhoto:
      "https://media.tacdn.com/media/attractions-splice-spp-674x446/11/c0/92/7d.jpg",
  },
  {
    id: 4,
    location: "Madrid",
    host: "Apartamentos",
    date: "11 de ene. de 2024 – 12 de ene. de 2024",
    photo: "https://randomuser.me/api/portraits/women/4.jpg",
    placePhoto:
      "https://www.barcelo.com/guia-turismo/wp-content/uploads/2019/12/portada.jpg",
  },
  {
    id: 5,
    location: "Milán",
    host: "Nathan",
    date: "22 de dic. de 2023 – 25 de dic. de 2023",
    photo: "https://randomuser.me/api/portraits/men/5.jpg",
    placePhoto:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVwysSl7o2-v2iStYdIPy2fNGSMDV8C7dhEw&s",
  },
  {
    id: 6,
    location: "Roma",
    host: "Anna",
    date: "14 de dic. de 2023 – 17 de dic. de 2023",
    photo: "https://randomuser.me/api/portraits/women/6.jpg",
    placePhoto:
      "https://historia.nationalgeographic.com.es/medio/2019/12/11/coliseo-roma_2924b6ae_1280x720.jpg",
  },
  {
    id: 7,
    location: "París",
    host: "Paula",
    date: "24 de sep. de 2023 – 27 de sep. de 2023",
    photo: "https://randomuser.me/api/portraits/women/7.jpg",
    placePhoto:
      "https://images.adsttc.com/media/images/5d44/14fa/284d/d1fd/3a00/003d/large_jpg/eiffel-tower-in-paris-151-medium.jpg?1564742900",
  },
];

const History = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        fontWeight="bold"
        sx={{ mb: 4 }}
      >
        Aventuras
      </Typography>

      <Box sx={{ bgcolor: "#f8f8f8", borderRadius: 2, p: 4, mb: 6 }}>
        <Typography variant="h5" gutterBottom fontWeight="medium">
          No tienes ninguna aventura reservada... ¡por ahora!
        </Typography>
        <Typography variant="body1" color="text.secondary" gutterBottom>
          Saca las maletas del clóset y comienza a planear tu próxima aventura.
        </Typography>
        <Button
          variant="contained"
          startIcon={<Search />}
          sx={{
            mt: 3,
            bgcolor: "#3b93b8",
            "&:hover": { bgcolor: "#2a7a9b" },
            borderRadius: 2,
            textTransform: "none",
            fontWeight: "bold",
          }}
        >
          Empieza a buscar
        </Button>
      </Box>

      <Typography variant="h5" gutterBottom fontWeight="bold" sx={{ mb: 3 }}>
        Dónde has estado
      </Typography>

      <Box
        sx={{
          bgcolor: "#f8f8f8",
          borderRadius: 2,
          p: 4,
        }}
      >
        <Grid container spacing={3}>
          {pastTrips.map((trip) => (
            <Grid item xs={12} sm={6} md={4} key={trip.id}>
              <Card
                elevation={0}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: 2,
                  borderRadius: 2,
                  overflow: "hidden",
                  transition: "0.3s",
                  bgcolor: "#fff",
                  "&:hover": { boxShadow: 3 },
                }}
              >
                <CardMedia
                  component="img"
                  image={trip.placePhoto}
                  alt={trip.location}
                  sx={{ width: 80, height: 80, borderRadius: 2, mr: 2 }}
                />
                <CardContent sx={{ p: 0 }}>
                  <Typography variant="h6" component="div" fontWeight="bold">
                    {trip.location}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Anfitrión: {trip.host}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    {trip.date}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default History;
