import React, { useState } from "react";
import {
  CardMedia,
  Typography,
  Box,
  IconButton,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import VerifiedIcon from "@mui/icons-material/Verified";
import Slider from "react-slick";
import PropTypes from "prop-types";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";

const SpaceCard = ({ space }) => {
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const [isFavorite, setIsFavorite] = useState(
    space.likes?.includes(user?.uid)
  );
  const [openDialog, setOpenDialog] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleFavoriteClick = async (e) => {
    e.stopPropagation();
    if (!user) {
      setOpenDialog(true);
      return;
    }

    try {
      const spaceRef = doc(db, "Ways", space.id);
      if (isFavorite) {
        await updateDoc(spaceRef, {
          likes: arrayRemove(user.uid),
        });
      } else {
        await updateDoc(spaceRef, {
          likes: arrayUnion(user.uid),
        });
      }
      setIsFavorite(!isFavorite);
    } catch (error) {
      console.error("Error updating likes:", error);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box
      onClick={() => navigate(`/space/${space.id}`)}
      sx={{
        cursor: "pointer",
        mb: 4,
        borderBottom: "1px solid #e0e0e0",
        pb: 2,
        position: "relative",
      }}
    >
      {space.boost && (
        <Typography
          variant="caption"
          sx={{
            position: "absolute",
            top: 10,
            left: 10,
            backgroundColor: "#fdfdfe",
            padding: "2px 8px",
            borderRadius: "4px",
            fontWeight: "bold",
            zIndex: 1,
          }}
        >
          Promocionado
        </Typography>
      )}
      <Box sx={{ position: "relative" }}>
        {space.photos && space.photos.length > 0 ? (
          <Slider {...settings}>
            {space.photos.slice(0, 5).map((image, index) => (
              <CardMedia
                component="img"
                height={220}
                image={image}
                alt={`Foto del espacio ${space.title} ${index + 1}`}
                key={index}
                sx={{ objectFit: "cover", borderRadius: "16px" }}
              />
            ))}
          </Slider>
        ) : (
          <CardMedia
            component="img"
            height={220}
            image="https://via.placeholder.com/220"
            alt="Imagen no disponible"
            sx={{ objectFit: "cover", borderRadius: "16px" }}
          />
        )}
        <IconButton
          onClick={handleFavoriteClick}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" },
          }}
        >
          {isFavorite ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon />}
        </IconButton>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6" fontWeight="bold" noWrap>
          {space.title}
        </Typography>
        <Box display="flex" alignItems="center" mb={1}>
          <LocationOnIcon fontSize="small" color="action" sx={{ mr: 1 }} />
          <Typography variant="body2" color="text.secondary" noWrap>
            {space.address.city}, {space.address.state}, {space.address.country}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={1}>
          <AttachMoneyIcon fontSize="small" color="action" sx={{ mr: 1 }} />
          <Typography
            variant="body2"
            color="text.primary"
            fontWeight="bold"
            noWrap
          >
            {space.pricePerNight} / noche
          </Typography>
        </Box>
        {space.pricePerMonth && (
          <Box display="flex" alignItems="center" mb={1}>
            <CalendarTodayIcon fontSize="small" color="action" sx={{ mr: 1 }} />
            <Typography variant="body2" color="text.secondary" noWrap>
              {space.pricePerMonth} / mes
            </Typography>
          </Box>
        )}
        {space.host && (
          <Box display="flex" alignItems="center" mt={2}>
            {space.host.photo && (
              <Avatar
                src={space.host.photo}
                alt={space.host.name}
                sx={{ mr: 1, width: 24, height: 24 }}
              />
            )}
            <Typography variant="body2" color="text.secondary" noWrap>
              {space.host.name}
            </Typography>
            {space.host.verified && (
              <VerifiedIcon fontSize="small" color="primary" sx={{ ml: 1 }} />
            )}
          </Box>
        )}
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Iniciar sesión</DialogTitle>
        <DialogContent>
          <Typography>
            Debes iniciar sesión para guardar este espacio en tus favoritos.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cerrar</Button>
          <Button onClick={() => navigate("/login")} color="primary">
            Iniciar sesión
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

SpaceCard.propTypes = {
  space: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    photos: PropTypes.arrayOf(PropTypes.string),
    address: PropTypes.shape({
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      zone: PropTypes.string,
    }).isRequired,
    guests: PropTypes.number.isRequired,
    pricePerNight: PropTypes.number.isRequired,
    pricePerMonth: PropTypes.number,
    host: PropTypes.shape({
      name: PropTypes.string,
      photo: PropTypes.string,
      verified: PropTypes.bool,
    }),
    boost: PropTypes.bool,
    likes: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default SpaceCard;
