import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, CircularProgress, Container } from "@mui/material";
import {
  doc,
  getDoc,
  query,
  where,
  collection,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase";
import { format } from "date-fns";
import { es } from "date-fns/locale";

const BookingList = () => {
  const { uid } = useParams();
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBookings = async () => {
      const bookingsRef = collection(db, "bookings");
      const q = query(bookingsRef, where("spaceId", "==", uid));
      const querySnapshot = await getDocs(q);
      const bookingsData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        startDate: doc.data().startDate.toDate(),
        endDate: doc.data().endDate.toDate(),
      }));
      setBookings(bookingsData);
      setLoading(false);
    };

    fetchBookings();
  }, [uid]);

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        Fechas reservadas:
      </Typography>
      {bookings.length === 0 ? (
        <Typography variant="body2" color="text.secondary">
          No hay fechas apartadas.
        </Typography>
      ) : (
        bookings.map((booking, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <Typography variant="body2" color="text.secondary">
              {format(booking.startDate, "d 'de' MMMM", { locale: es })} -{" "}
              {format(booking.endDate, "d 'de' MMMM", { locale: es })}
            </Typography>
          </Box>
        ))
      )}
    </Container>
  );
};

export default BookingList;
