import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  Grid,
  CircularProgress,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import PropTypes from "prop-types"; // Import PropTypes
import { useUserAuth } from "../../context/userAuthContext";
import { useNavigate } from "react-router-dom";
import { db, storage } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#3b93b8",
  color: "white",
  "&:hover": {
    backgroundColor: "#2c7a9b",
  },
}));

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#3b93b8",
    },
    "&:hover fieldset": {
      borderColor: "#2c7a9b",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#3b93b8",
    },
  },
});

const PhotoUploadCard = ({ title, description, onFileChange, preview }) => (
  <Card sx={{ mb: 3, boxShadow: 3, borderRadius: "2%" }}>
    <CardContent>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        {description}
      </Typography>
      <StyledButton
        variant="contained"
        component="label"
        startIcon={<PhotoCameraIcon />}
      >
        Tomar Foto
        <input
          type="file"
          hidden
          accept="image/*"
          capture="environment"
          onChange={onFileChange}
        />
      </StyledButton>
    </CardContent>
    {preview && (
      <CardMedia
        component="img"
        image={preview}
        alt={title}
        sx={{ height: 200, objectFit: "cover", borderRadius: "5%" }}
      />
    )}
  </Card>
);

// Add PropTypes validation
PhotoUploadCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onFileChange: PropTypes.func.isRequired,
  preview: PropTypes.string,
};

const UserVerification = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState(user ? user.email : "");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoPreview, setProfilePhotoPreview] = useState("");
  const [idPhoto, setIdPhoto] = useState(null);
  const [idPhotoPreview, setIdPhotoPreview] = useState("");
  const [faceAndIdPhoto, setFaceAndIdPhoto] = useState(null);
  const [faceAndIdPhotoPreview, setFaceAndIdPhotoPreview] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const uploadToStorage = async (file, folder, filename) => {
    if (!file) return null;
    const fileRef = ref(storage, `${folder}/${filename}`);
    await uploadBytes(fileRef, file);
    return getDownloadURL(fileRef);
  };

  const handleFileChange = (event, setter, previewSetter) => {
    const file = event.target.files[0];
    if (file) {
      setter(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        previewSetter(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (!user || !user.uid) {
      console.error("User data is not fully loaded.");
      setIsSubmitting(false);
      return;
    }

    const emailToUse = email || user.email || "default@email.com";

    try {
      const profilePhotoUrl = await uploadToStorage(
        profilePhoto,
        `users/${user.uid}`,
        "profilePhoto.jpg"
      );
      const idPhotoUrl = await uploadToStorage(
        idPhoto,
        `users/${user.uid}`,
        "idPhoto.jpg"
      );
      const faceAndIdPhotoUrl = await uploadToStorage(
        faceAndIdPhoto,
        `users/${user.uid}`,
        "faceAndIdPhoto.jpg"
      );

      const userInfo = {
        fullName,
        email: emailToUse,
        phoneNumber,
        profilePhotoUrl,
        idPhotoUrl,
        faceAndIdPhotoUrl,
        status: "Pending",
        verified: false,
      };

      await setDoc(doc(db, "Users", user.uid), userInfo);

      console.log("Información del usuario guardada con éxito.");
      navigate(`/VerificationStatus/${user.uid}`);
    } catch (error) {
      console.error("Error saving user information:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 5 }}>
      <Typography
        variant="h3"
        gutterBottom
        sx={{ fontWeight: "bold", color: "#3b93b8", mb: 4 }}
      >
        Verificación de Usuario
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <StyledTextField
              fullWidth
              label="Nombre Completo"
              variant="outlined"
              required
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              placeholder="Ingrese su nombre completo"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledTextField
              fullWidth
              label="Correo Electrónico"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Ingrese su correo electrónico"
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              fullWidth
              label="Número de Teléfono"
              variant="outlined"
              required
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Ingrese su número de teléfono"
            />
          </Grid>
        </Grid>

        <Typography variant="body1" color="error" sx={{ mt: 4, mb: 3 }}>
          Nota: Si los datos no se alcanzan a visualizar claramente, tu
          verificación será rechazada.
        </Typography>

        <PhotoUploadCard
          title="Foto de Perfil"
          description="Sube una foto clara de tu rostro"
          onFileChange={(e) =>
            handleFileChange(e, setProfilePhoto, setProfilePhotoPreview)
          }
          preview={profilePhotoPreview}
        />

        <PhotoUploadCard
          title="Identificación Oficial"
          description="Sube una foto de tu identificación oficial"
          onFileChange={(e) =>
            handleFileChange(e, setIdPhoto, setIdPhotoPreview)
          }
          preview={idPhotoPreview}
        />

        <PhotoUploadCard
          title="Foto con Identificación"
          description="Sube una foto de tu rostro mostrando tu identificación oficial visible"
          onFileChange={(e) =>
            handleFileChange(e, setFaceAndIdPhoto, setFaceAndIdPhotoPreview)
          }
          preview={faceAndIdPhotoPreview}
        />

        <Box sx={{ mt: 4, textAlign: "center" }}>
          <StyledButton
            type="submit"
            variant="contained"
            size="large"
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} /> : "Verificar"}
          </StyledButton>
        </Box>
      </form>

      {isSubmitting && (
        <Box sx={{ mt: 4, textAlign: "center" }}>
          <Typography variant="h5" sx={{ mb: 2, color: "#3b93b8" }}>
            Tu cuenta está siendo verificada por un Moderador de Bexway
          </Typography>
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
};

export default UserVerification;
