import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Grid,
  TextField,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#3b93b8",
  color: "white",
  "&:hover": {
    backgroundColor: "#2c7a9b",
  },
}));

const BookingPreferencesForm = ({ wayId, onNext }) => {
  const [pricePerNight, setPricePerNight] = useState("");
  const [pricePerMonth, setPricePerMonth] = useState("");
  const [pricePerYear, setPricePerYear] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchWayData = async () => {
      if (wayId) {
        const docRef = doc(db, "Ways", wayId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const wayData = docSnap.data();
          setPricePerNight(wayData.pricePerNight || "");
          setPricePerMonth(wayData.pricePerMonth || "");
          setPricePerYear(wayData.pricePerYear || "");
        }
      }
    };
    fetchWayData();
  }, [wayId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      const docRef = doc(db, "Ways", wayId);
      await updateDoc(docRef, {
        pricePerNight,
        pricePerMonth,
        pricePerYear,
        finished: true,
      });
      onNext();
    } catch (error) {
      console.error("Error updating document: ", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography variant="h5" gutterBottom>
        Establece tus precios
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Precio por noche ($)"
            variant="outlined"
            required
            value={pricePerNight}
            onChange={(e) => setPricePerNight(e.target.value)}
            placeholder="Obligatorio"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Precio por mes ($)"
            variant="outlined"
            value={pricePerMonth}
            onChange={(e) => setPricePerMonth(e.target.value)}
            placeholder="Opcional"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Precio por año ($)"
            variant="outlined"
            value={pricePerYear}
            onChange={(e) => setPricePerYear(e.target.value)}
            placeholder="Opcional"
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ textAlign: "center" }}>
            <StyledButton
              type="submit"
              variant="contained"
              size="large"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size={24} />
              ) : (
                "Guardar y Publicar"
              )}
            </StyledButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

BookingPreferencesForm.propTypes = {
  wayId: PropTypes.string.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default BookingPreferencesForm;
