import React, { useState } from "react";
import {
  Typography,
  Container,
  Grid,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useUserAuth } from "../../context/userAuthContext";
import EspacioForm from "./EspacioForm";
import ExperienciaForm from "./ExperienciaForm";
import { db, storage } from "../../firebase";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#3b93b8",
  color: "white",
  "&:hover": {
    backgroundColor: "#2c7a9b",
  },
}));

const WayCreator = () => {
  const { user } = useUserAuth();
  const [wayType, setWayType] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [photoPreviews, setPhotoPreviews] = useState([]);

  const uploadToStorage = async (file, folder, filename) => {
    if (!file) return null;
    const fileRef = ref(storage, `${folder}/${filename}`);
    await uploadBytes(fileRef, file);
    return getDownloadURL(fileRef);
  };

  const handlePhotoChange = (event) => {
    const files = Array.from(event.target.files);
    setPhotos(files);
    const previews = files.map((file) => URL.createObjectURL(file));
    setPhotoPreviews(previews);
  };

  const handleRemovePhoto = (index) => {
    const updatedPhotos = [...photos];
    const updatedPreviews = [...photoPreviews];
    updatedPhotos.splice(index, 1);
    updatedPreviews.splice(index, 1);
    setPhotos(updatedPhotos);
    setPhotoPreviews(updatedPreviews);
  };

  const handleSubmit = async (wayData) => {
    setIsSubmitting(true);

    if (!user || !user.uid) {
      console.error("User data is not fully loaded.");
      setIsSubmitting(false);
      return;
    }

    const uploadedPhotoUrls = await Promise.all(
      photos.map((photo, index) =>
        uploadToStorage(photo, `ways/${user.uid}`, `photo${index}.jpg`)
      )
    );

    wayData = {
      ...wayData,
      fechaDePublicacion: serverTimestamp(),
      creatorUID: user.uid,
      photos: uploadedPhotoUrls,
    };

    try {
      await setDoc(doc(db, "Ways", `${user.uid}_${Date.now()}`), wayData);
      console.log("Way data saved successfully.");
    } catch (error) {
      console.error("Error saving way data:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 5 }}>
      <Typography
        variant="h3"
        gutterBottom
        sx={{ fontWeight: "bold", color: "#3b93b8", mb: 4 }}
      >
        Crear Way
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Tipo de Way</InputLabel>
            <Select
              value={wayType}
              onChange={(e) => setWayType(e.target.value)}
              label="Tipo de Way"
            >
              <MenuItem value="Espacio">Espacio</MenuItem>
              <MenuItem value="Experiencia">Experiencia</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {wayType === "Espacio" && (
          <EspacioForm
            onSubmit={handleSubmit}
            handlePhotoChange={handlePhotoChange}
            handleRemovePhoto={handleRemovePhoto}
            photoPreviews={photoPreviews}
            isSubmitting={isSubmitting}
          />
        )}
        {wayType === "Experiencia" && (
          <ExperienciaForm
            onSubmit={handleSubmit}
            handlePhotoChange={handlePhotoChange}
            handleRemovePhoto={handleRemovePhoto}
            photoPreviews={photoPreviews}
            isSubmitting={isSubmitting}
          />
        )}
      </Grid>
      {isSubmitting && (
        <Box sx={{ mt: 4, textAlign: "center" }}>
          <Typography variant="h5" sx={{ mb: 2, color: "#3b93b8" }}>
            Tu Way está siendo publicado
          </Typography>
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
};

export default WayCreator;
