import React, { useState, useEffect } from "react";
import { useUserAuth } from "../../context/userAuthContext";
import {
  Button,
  Typography,
  Box,
  Avatar,
  IconButton,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import {
  Notifications,
  Person,
  Settings,
  CardGiftcard,
  GroupAdd,
  HelpOutline,
  Security,
  Home,
  Translate,
} from "@mui/icons-material";
import { CheckCircle as VerifiedIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { doc, onSnapshot } from "firebase/firestore";

const UserDashboard = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (user && user.uid) {
      const docRef = doc(db, "Users", user.uid);
      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          setUserInfo(docSnap.data());
        } else {
          console.log("No such document!");
        }
      });

      return () => unsubscribe(); // Cleanup subscription on unmount
    }
  }, [user]);

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <Container maxWidth="sm" sx={{ py: 4 }}>
      <Box mb={4} textAlign="center">
        <Typography variant="h5" gutterBottom>
          Perfil
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" mb={4}>
        <Avatar
          src={user?.photoURL || "https://via.placeholder.com/40"}
          alt={user?.displayName || "User Name"}
          sx={{ width: 56, height: 56, mr: 2 }}
        />
        <Box display="flex" alignItems="center">
          <Typography variant="h6" sx={{ mr: 1 }}>
            {user?.displayName || "User Name"}
          </Typography>
          {userInfo?.verified && <VerifiedIcon color="primary" />}
        </Box>
        <IconButton sx={{ ml: "auto" }}>
          <Notifications />
        </IconButton>
      </Box>

      <Box
        sx={{
          p: 2,
          backgroundColor: "#f9f9f9",
          borderRadius: 2,
          mb: 4,
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => handleNavigation("/plans")}
      >
        <Box>
          <Typography variant="h6">Modo Anfitrión</Typography>
          <Typography variant="body2" color="text.secondary">
            Publica un anuncio y empieza a ganar dinero.
          </Typography>
        </Box>
        <Box sx={{ ml: "auto" }}>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/bexway-98cca.appspot.com/o/iconos%2Fmercadeo-en-linea.png?alt=media&token=baa552ab-2ea3-4b56-b987-23f0264bab20"
            alt="Anuncio"
            style={{ borderRadius: "8px", width: 56, height: 56 }}
          />
        </Box>
      </Box>

      <List>
        <ListItem button onClick={() => handleNavigation("/personal-info")}>
          <ListItemIcon>
            <Person />
          </ListItemIcon>
          <ListItemText primary="Información personal" />
        </ListItem>
        <ListItem button onClick={() => handleNavigation("/account")}>
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText primary="Cuenta" />
        </ListItem>
      </List>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h6" gutterBottom>
        Menu Temporal
      </Typography>
      <List>
        <ListItem button onClick={() => handleNavigation("/newspace")}>
          <ListItemIcon>
            <CardGiftcard />
          </ListItemIcon>
          <ListItemText primary="Nuevo Alojamiento" />
        </ListItem>
        <ListItem button onClick={() => handleNavigation("/spacemanager")}>
          <ListItemIcon>
            <GroupAdd />
          </ListItemIcon>
          <ListItemText primary="Mis Alojamientos" />
        </ListItem>
      </List>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h6" gutterBottom>
        Asistencia
      </Typography>
      <List>
        <ListItem button onClick={() => handleNavigation("/help-center")}>
          <ListItemIcon>
            <HelpOutline />
          </ListItemIcon>
          <ListItemText primary="Visita el Centro de ayuda" />
        </ListItem>
        <ListItem button onClick={() => handleNavigation("/security-help")}>
          <ListItemIcon>
            <Security />
          </ListItemIcon>
          <ListItemText primary="Ayuda con problemas de seguridad" />
        </ListItem>
        <ListItem
          button
          onClick={() => handleNavigation("/neighborhood-issues")}
        >
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText primary="¿Problemas en tu colonia?" />
        </ListItem>
        <ListItem button onClick={() => handleNavigation("/how-it-works")}>
          <ListItemIcon>
            <Translate />
          </ListItemIcon>
          <ListItemText primary="Cómo funciona Airbnb" />
        </ListItem>
      </List>

      <Divider sx={{ my: 2 }} />

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
      >
        <Box>
          <Typography variant="body2">Español (MX)</Typography>
          <Typography variant="body2">$ MXN</Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default UserDashboard;
