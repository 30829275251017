import React from "react";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import {
  Explore,
  Favorite,
  FlightTakeoff,
  Message,
  AccountCircle,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "./context/userAuthContext";

const MobileNavbar = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const { user } = useUserAuth();

  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (!user && newValue !== 0) {
      navigate("/login");
    } else {
      // Navegar a la ruta correspondiente
      switch (newValue) {
        case 0:
          navigate("/");
          break;
        case 1:
          navigate("/favorites");
          break;
        case 2:
          navigate("/trips");
          break;
        case 3:
          navigate("/messages");
          break;
        case 4:
          navigate("/profile");
          break;
        default:
          break;
      }
    }
  };

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      showLabels
      sx={{
        width: "100%",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000, // Asegura que la barra se mantenga sobre otros elementos
        borderTop: "1px solid #e0e0e0", // Agrega una línea gris claro en la parte superior
        "& .Mui-selected, .Mui-selected svg": {
          color: "#3b93b8", // Cambia 'green' por el color que prefieras
        },
      }}
    >
      <BottomNavigationAction label="Explorar" icon={<Explore />} />
      <BottomNavigationAction label="Favoritos" icon={<Favorite />} />
      <BottomNavigationAction label="Viajes" icon={<FlightTakeoff />} />
      <BottomNavigationAction label="Mensajes" icon={<Message />} />
      <BottomNavigationAction label="Perfil" icon={<AccountCircle />} />
    </BottomNavigation>
  );
};

export default MobileNavbar;
