import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Paper,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { DateRangePicker, DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { styled, useTheme } from "@mui/material/styles";
import { addDays, differenceInDays, format } from "date-fns";
import { es } from "date-fns/locale";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: "16px",
  boxShadow: "0 6px 16px rgba(0,0,0,0.12)",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
    boxShadow: "none",
    backgroundColor: "transparent",
  },
}));

const StyledDateRangePicker = styled(DateRangePicker)(({ theme }) => ({
  "& .rdrDefinedRangesWrapper, & .rdrStaticRange, & .rdrStaticRangeLabel": {
    display: "none",
  },
  "& .rdrCalendarWrapper": {
    fontSize: "16px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  "& .rdrMonth": {
    width: "100%",
  },
  "& .rdrMonthAndYearPickers": {
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  "& .rdrMonthName": {
    fontSize: "16px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  "& .rdrDayNumber span": {
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  "& .rdrStartEdge, & .rdrEndEdge, & .rdrInRange": {
    color: "#3e94b9",
  },
  "& .rdrDayToday .rdrDayNumber span:after": {
    background: "#3e94b9",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#3e94b9",
  color: "white",
  borderRadius: "8px",
  padding: "10px 20px",
  "&:hover": {
    backgroundColor: "#2c7a9b",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "8px 16px",
  },
}));

const Calendar = () => {
  const { uid } = useParams();
  const [space, setSpace] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchSpace = async () => {
      try {
        const docRef = doc(db, "Ways", uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setSpace(docSnap.data());
        }
      } catch (error) {
        console.error("Error fetching space:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSpace();
  }, [uid]);

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
  };

  const nightsCount = differenceInDays(
    dateRange[0].endDate,
    dateRange[0].startDate
  );

  return (
    <Box sx={{ mt: 2 }}>
      {space && (
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          {space.title}
        </Typography>
      )}
      <StyledPaper elevation={isMobile ? 0 : 3}>
        <Typography variant="h6" gutterBottom fontWeight="bold">
          Selecciona tus fechas
        </Typography>
        <StyledDateRangePicker
          ranges={dateRange}
          onChange={handleSelect}
          months={isMobile ? 1 : 2}
          direction={isMobile ? "vertical" : "horizontal"}
          locale={es}
          minDate={new Date()}
          rangeColors={["#3e94b9"]}
        />
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" fontWeight="bold">
            {nightsCount} {nightsCount === 1 ? "noche" : "noches"}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {format(dateRange[0].startDate, "d 'de' MMMM", { locale: es })} -{" "}
            {format(dateRange[0].endDate, "d 'de' MMMM", { locale: es })}
          </Typography>
        </Box>

        <StyledButton variant="contained" fullWidth sx={{ mt: 2, py: 1.5 }}>
          Reservar
        </StyledButton>
      </StyledPaper>
    </Box>
  );
};

export default Calendar;
