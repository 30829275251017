import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Paper,
  useMediaQuery,
  Container,
} from "@mui/material";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  collection,
  doc,
  getDoc,
  addDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase";
import { styled, useTheme } from "@mui/material/styles";
import {
  addDays,
  differenceInDays,
  format,
  isWithinInterval,
  eachDayOfInterval,
  startOfDay,
  endOfDay,
  isSameDay,
  isAfter,
  isBefore,
} from "date-fns";
import { es } from "date-fns/locale";
import { useUserAuth } from "../../context/userAuthContext";
import BookingList from "./BookingList";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: "16px",
  boxShadow: "0 6px 16px rgba(0,0,0,0.12)",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
    boxShadow: "none",
    backgroundColor: "transparent",
  },
}));

const StyledDateRangePicker = styled(DateRangePicker)(({ theme }) => ({
  "& .rdrDefinedRangesWrapper, & .rdrStaticRange, & .rdrStaticRangeLabel": {
    display: "none",
  },
  "& .rdrCalendarWrapper": {
    fontSize: "16px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  "& .rdrMonth": {
    width: "100%",
  },
  "& .rdrMonthAndYearPickers": {
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  "& .rdrMonthName": {
    fontSize: "16px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  "& .rdrDayNumber span": {
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  "& .rdrStartEdge, & .rdrEndEdge, & .rdrInRange": {
    color: "#3e94b9",
  },
  "& .rdrDayToday .rdrDayNumber span:after": {
    background: "#3e94b9",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#3e94b9",
  color: "white",
  borderRadius: "8px",
  padding: "10px 20px",
  "&:hover": {
    backgroundColor: "#2c7a9b",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "8px 16px",
  },
}));

const BookingManager = () => {
  const { uid } = useParams();
  const { user } = useUserAuth();
  const [space, setSpace] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const [bookings, setBookings] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchSpace = async () => {
      try {
        const docRef = doc(db, "Ways", uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setSpace(docSnap.data());
        }
      } catch (error) {
        console.error("Error fetching space:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchBookings = async () => {
      const bookingsRef = collection(db, "bookings");
      const q = query(bookingsRef, where("spaceId", "==", uid));
      const querySnapshot = await getDocs(q);
      const bookingsData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        startDate: doc.data().startDate.toDate(),
        endDate: doc.data().endDate.toDate(),
      }));
      setBookings(bookingsData);
    };

    fetchSpace();
    fetchBookings();
  }, [uid]);

  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
  };

  const handleBook = async () => {
    const nightsCount = differenceInDays(
      dateRange[0].endDate,
      dateRange[0].startDate
    );

    const newBooking = {
      spaceId: uid,
      userId: user.uid,
      startDate: startOfDay(dateRange[0].startDate),
      endDate: endOfDay(dateRange[0].endDate),
      createdAt: new Date(),
      status: "pending",
      totalPrice: nightsCount * space.pricePerNight,
    };

    try {
      await addDoc(collection(db, "bookings"), newBooking);
      setBookings([
        ...bookings,
        {
          ...newBooking,
          startDate: newBooking.startDate,
          endDate: newBooking.endDate,
        },
      ]);
    } catch (error) {
      console.error("Error booking dates:", error);
    }
  };

  const checkIfDateIsReserved = (startDate, endDate) => {
    return bookings.some(
      (booking) =>
        isWithinInterval(startDate, {
          start: startOfDay(booking.startDate),
          end: endOfDay(booking.endDate),
        }) ||
        isWithinInterval(endDate, {
          start: startOfDay(booking.startDate),
          end: endOfDay(booking.endDate),
        }) ||
        (startDate < startOfDay(booking.startDate) &&
          endDate > endOfDay(booking.endDate)) ||
        isSameDay(startDate, booking.startDate) ||
        isSameDay(endDate, booking.endDate)
    );
  };

  const getDisabledDates = () => {
    let disabledDates = [];
    bookings.forEach((booking) => {
      let interval = eachDayOfInterval({
        start: startOfDay(booking.startDate),
        end: endOfDay(booking.endDate),
      });
      interval.pop(); // Remove the last day to allow new bookings on the checkout day
      disabledDates = [...disabledDates, ...interval];
    });
    return disabledDates;
  };

  const isDateDisabled = (date) => {
    const disabledDates = getDisabledDates();
    return disabledDates.some(
      (disabledDate) =>
        format(date, "yyyy-MM-dd") === format(disabledDate, "yyyy-MM-dd")
    );
  };

  const nightsCount = differenceInDays(
    dateRange[0].endDate,
    dateRange[0].startDate
  );

  const totalPrice = nightsCount * (space ? space.pricePerNight : 0);

  const isDateRangeAvailable = !checkIfDateIsReserved(
    dateRange[0].startDate,
    dateRange[0].endDate
  );

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {space && (
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          {space.title}
        </Typography>
      )}
      <StyledPaper elevation={isMobile ? 0 : 3}>
        <Typography variant="h6" gutterBottom fontWeight="bold">
          Selecciona tus fechas
        </Typography>
        <StyledDateRangePicker
          ranges={dateRange}
          onChange={handleSelect}
          months={isMobile ? 1 : 2}
          direction={isMobile ? "vertical" : "horizontal"}
          locale={es}
          minDate={new Date()}
          rangeColors={["#3e94b9"]}
          disabledDates={getDisabledDates()}
          disabledDay={(date) => isDateDisabled(date)}
        />
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" fontWeight="bold">
            {nightsCount} {nightsCount === 1 ? "noche" : "noches"}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {format(dateRange[0].startDate, "d 'de' MMMM", { locale: es })} -{" "}
            {format(dateRange[0].endDate, "d 'de' MMMM", { locale: es })}
          </Typography>
          <Typography variant="body1" fontWeight="bold">
            Precio total: ${totalPrice}
          </Typography>
        </Box>
        <StyledButton
          variant="contained"
          fullWidth
          sx={{ mt: 2, py: 1.5 }}
          onClick={handleBook}
          disabled={!isDateRangeAvailable}
        >
          Reservar
        </StyledButton>
        {!isDateRangeAvailable && (
          <Typography variant="body2" color="error" sx={{ mt: 2 }}>
            Las fechas seleccionadas no están disponibles.
          </Typography>
        )}
      </StyledPaper>
      <BookingList />
    </Container>
  );
};

export default BookingManager;
