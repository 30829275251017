import React, { useState, useEffect } from "react";
import {
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";

const amenitiesList = [
  "Wifi",
  "TV",
  "Cocina",
  "Lavadora",
  "Estacionamiento gratuito en las instalaciones",
  "Estacionamiento de pago en las instalaciones",
  "Aire acondicionado",
  "Área para trabajar",
];

const specialAmenitiesList = [
  "Alberca",
  "Jacuzzi",
  "Patio",
  "Barbacoa",
  "Comedor al aire libre",
  "Lugar para hacer fogata",
  "Mesa de billar",
  "Chimenea interior",
  "Piano",
  "Equipo para hacer ejercicio",
  "Acceso al lago",
  "Acceso a la playa",
  "Accesos de entrada y salida a pistas de esquí",
  "Regadera exterior",
];

const safetyItemsList = [
  "Detector de humo",
  "Botiquín",
  "Extintor de incendios",
  "Detector de monóxido de carbono",
];

const AmenitiesForm = ({ wayId, onNext }) => {
  const { user } = useUserAuth();
  const [amenities, setAmenities] = useState([]);
  const [specialAmenities, setSpecialAmenities] = useState([]);
  const [safetyItems, setSafetyItems] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      if (wayId) {
        const docRef = doc(db, "Ways", wayId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setAmenities(data.amenities || []);
          setSpecialAmenities(data.specialAmenities || []);
          setSafetyItems(data.safetyItems || []);
        }
      }
    };
    loadData();
  }, [wayId]);

  const handleAmenityChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setAmenities([...amenities, name]);
    } else {
      setAmenities(amenities.filter((amenity) => amenity !== name));
    }
  };

  const handleSpecialAmenityChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setSpecialAmenities([...specialAmenities, name]);
    } else {
      setSpecialAmenities(
        specialAmenities.filter((amenity) => amenity !== name)
      );
    }
  };

  const handleSafetyItemChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setSafetyItems([...safetyItems, name]);
    } else {
      setSafetyItems(safetyItems.filter((item) => item !== name));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (!user || !user.uid) {
      console.error("User data is not fully loaded.");
      setIsSubmitting(false);
      return;
    }

    const amenitiesData = {
      amenities,
      specialAmenities,
      safetyItems,
    };

    try {
      const docRef = doc(db, "Ways", wayId);
      await setDoc(docRef, amenitiesData, { merge: true });
      console.log("Amenities data saved successfully");
      onNext();
    } catch (error) {
      console.error("Error saving amenities data:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6">
            Estos son los servicios preferidos por los huéspedes. ¿Los tienes?
          </Typography>
        </Grid>
        {amenitiesList.map((amenity) => (
          <Grid item xs={12} md={6} key={amenity}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={amenities.includes(amenity)}
                  onChange={handleAmenityChange}
                  name={amenity}
                />
              }
              label={amenity}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Typography variant="h6">
            ¿Ofreces algún servicio especial?
          </Typography>
        </Grid>
        {specialAmenitiesList.map((amenity) => (
          <Grid item xs={12} md={6} key={amenity}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={specialAmenities.includes(amenity)}
                  onChange={handleSpecialAmenityChange}
                  name={amenity}
                />
              }
              label={amenity}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Typography variant="h6">
            ¿Cuentas con alguno de estos elementos de seguridad?
          </Typography>
        </Grid>
        {safetyItemsList.map((item) => (
          <Grid item xs={12} md={6} key={item}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={safetyItems.includes(item)}
                  onChange={handleSafetyItemChange}
                  name={item}
                />
              }
              label={item}
            />
          </Grid>
        ))}
        <Box sx={{ mt: 4, textAlign: "center", width: "100%" }}>
          <Button
            type="submit"
            variant="contained"
            size="large"
            disabled={isSubmitting}
            sx={{
              backgroundColor: "#3b93b8",
              color: "white",
              "&:hover": {
                backgroundColor: "#2c7a9b",
              },
            }}
          >
            {isSubmitting ? (
              <CircularProgress size={24} />
            ) : (
              "Guardar Servicios"
            )}
          </Button>
        </Box>
      </Grid>
    </form>
  );
};

AmenitiesForm.propTypes = {
  wayId: PropTypes.string.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default AmenitiesForm;
