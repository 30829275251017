import React from "react";
import {
  Container,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  useTheme,
  useMediaQuery,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const plans = [
  { id: 1, accommodations: 1, price: 99.0 },
  { id: 2, accommodations: 2, price: 198.0 },
  { id: 3, accommodations: 3, price: 285.0 },
  { id: 4, accommodations: 4, price: 371.0 },
  { id: 5, accommodations: 5, price: 456.0 },
  { id: 6, accommodations: 6, price: 535.0 },
  { id: 7, accommodations: 7, price: 584.0 },
  { id: 8, accommodations: 8, price: 633.0 },
  { id: 9, accommodations: 9, price: 682.0 },
  { id: 10, accommodations: 10, price: 699.0 },
];

const HostMode = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleBuyPlan = (plan) => {
    alert(
      `Compra realizada: ${plan.accommodations} alojamientos por $${plan.price}`
    );
    navigate("/newhost");
  };

  return (
    <Container maxWidth="lg" sx={{ py: 5 }}>
      <Typography
        variant="h3"
        gutterBottom
        sx={{
          fontWeight: "bold",
          color: "#3b93b8",
          textAlign: "center",
          mb: 4,
        }}
      >
        Explora Nuestros Planes
      </Typography>
      <Grid container spacing={4}>
        {plans.map((plan) => (
          <Grid item xs={12} sm={6} md={4} key={plan.id}>
            <Card
              elevation={3}
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                transition:
                  "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: theme.shadows[10],
                },
              }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <HomeIcon sx={{ fontSize: 40, color: "#3b93b8", mr: 1 }} />
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{ color: "#3b93b8", fontWeight: "bold" }}
                  >
                    {plan.accommodations} Alojamiento
                    {plan.accommodations > 1 ? "s" : ""}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <AttachMoneyIcon
                    sx={{ color: theme.palette.text.secondary, mr: 1 }}
                  />
                  <Typography variant="h6" color="text.secondary">
                    ${plan.price.toFixed(2)}
                  </Typography>
                </Box>
              </CardContent>
              <CardActions sx={{ justifyContent: "center", pb: 2 }}>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => handleBuyPlan(plan)}
                  sx={{
                    bgcolor: "#3b93b8",
                    "&:hover": {
                      bgcolor: "#2c7a9b",
                    },
                    width: isMobile ? "100%" : "auto",
                  }}
                  startIcon={<ShoppingCartIcon />}
                >
                  Comprar
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default HostMode;
