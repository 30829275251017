import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Card,
  CardMedia,
  CardContent,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../firebase"; // Asegúrate de importar correctamente db y storage
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const StyledButton = styled(Button)({
  backgroundColor: "#3e94b9",
  color: "white",
  "&:hover": {
    backgroundColor: "#2c7a9b",
  },
});

const CategoryUpload = () => {
  const [categoryName, setCategoryName] = useState("");
  const [description, setDescription] = useState("");
  const [icon, setIcon] = useState(null);
  const [iconPreview, setIconPreview] = useState("");
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);

  const handleIconChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setIcon(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setIconPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let iconUrl = "";
      if (icon) {
        const iconRef = ref(storage, `categories/icons/${icon.name}`);
        await uploadBytes(iconRef, icon);
        iconUrl = await getDownloadURL(iconRef);
      }
      await addDoc(collection(db, "Categories"), {
        name: categoryName,
        description,
        iconUrl,
        createdAt: new Date(),
      });
      setCategoryName("");
      setDescription("");
      setIcon(null);
      setIconPreview("");
      fetchCategories();
    } catch (error) {
      console.error("Error uploading category: ", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "Categories"));
      const categoriesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategories(categoriesData);
    } catch (error) {
      console.error("Error fetching categories: ", error);
    }
  };

  const handleDelete = async () => {
    try {
      if (categoryToDelete) {
        await deleteDoc(doc(db, "Categories", categoryToDelete.id));
        fetchCategories();
      }
    } catch (error) {
      console.error("Error deleting category: ", error);
    } finally {
      setDeleteDialogOpen(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <Container maxWidth="sm">
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 4,
          p: 3,
          boxShadow: 3,
          borderRadius: 2,
        }}
      >
        <Typography variant="h5" gutterBottom>
          Subir Categoría
        </Typography>
        <TextField
          label="Nombre de la Categoría"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          fullWidth
          required
          sx={{ mb: 2 }}
        />
        <TextField
          label="Descripción"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          required
          sx={{ mb: 2 }}
        />
        <Button variant="contained" component="label" sx={{ mb: 2 }}>
          Subir Icono
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={handleIconChange}
          />
        </Button>
        {iconPreview && (
          <Box
            component="img"
            src={iconPreview}
            alt="Icon Preview"
            sx={{ width: "100%", height: "auto", mb: 2 }}
          />
        )}
        <StyledButton
          type="submit"
          variant="contained"
          disabled={loading}
          fullWidth
        >
          {loading ? <CircularProgress size={24} /> : "Subir Categoría"}
        </StyledButton>
      </Box>

      <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
        Categorías
      </Typography>
      <Grid container spacing={3}>
        {categories.map((category) => (
          <Grid item xs={12} sm={6} md={4} key={category.id}>
            <Card>
              <CardMedia
                component="img"
                height="140"
                image={category.iconUrl}
                alt={`Icono de ${category.name}`}
              />
              <CardContent>
                <Typography variant="h6" noWrap>
                  {category.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" noWrap>
                  {category.description}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {`Creado: ${new Date(
                    category.createdAt.seconds * 1000
                  ).toLocaleDateString()}`}
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton
                    color="secondary"
                    onClick={() => {
                      setCategoryToDelete(category);
                      setDeleteDialogOpen(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton color="primary">
                    <EditIcon />
                  </IconButton>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Eliminar Categoría</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que deseas eliminar la categoría{" "}
            {categoryToDelete?.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CategoryUpload;
