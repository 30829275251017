import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Avatar,
  CircularProgress,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { db } from "../../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PendingIcon from "@mui/icons-material/HourglassEmpty";
import CloseIcon from "@mui/icons-material/Close";

const Verification = () => {
  const { uid } = useParams();
  const [verificationData, setVerificationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [zoomImage, setZoomImage] = useState(null);

  useEffect(() => {
    const fetchVerificationData = async () => {
      try {
        const docRef = doc(db, "Users", uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setVerificationData(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVerificationData();
  }, [uid]);

  const handleZoom = (imageUrl) => {
    setZoomImage(imageUrl);
  };

  const handleCloseZoom = () => {
    setZoomImage(null);
  };

  const handleStatusChange = async (status) => {
    try {
      const docRef = doc(db, "Users", uid);
      await updateDoc(docRef, {
        status,
        verified: status === "Approved",
      });
      setVerificationData((prev) => ({
        ...prev,
        status,
        verified: status === "Approved",
      }));
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  if (loading) {
    return (
      <Container maxWidth="sm" sx={{ py: 5 }}>
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (!verificationData) {
    return (
      <Container maxWidth="sm" sx={{ py: 5 }}>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          No se encontraron datos de verificación para este usuario.
        </Typography>
      </Container>
    );
  }

  const statusIcon = {
    Pending: <PendingIcon color="warning" />,
    Approved: <CheckCircleIcon color="success" />,
    Rejected: <CancelIcon color="error" />,
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
        Detalles de Verificación
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          border: "1px solid #ddd",
          borderRadius: 2,
          boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          p: 3,
        }}
      >
        <Avatar
          src={verificationData.profilePhotoUrl}
          alt={verificationData.fullName}
          sx={{ width: 100, height: 100, mb: 2 }}
          onClick={() => handleZoom(verificationData.profilePhotoUrl)}
        />
        <Typography variant="h6">{verificationData.fullName}</Typography>
        <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
          {verificationData.email}
        </Typography>
        <Typography variant="body1">
          Número de Teléfono: {verificationData.phoneNumber}
        </Typography>
        <Typography
          variant="body1"
          sx={{ display: "flex", alignItems: "center" }}
        >
          Estado: {verificationData.status}{" "}
          {statusIcon[verificationData.status]}
        </Typography>
        <Typography variant="body1">
          Verificado: {verificationData.verified ? "Sí" : "No"}
        </Typography>
        <Box sx={{ mt: 3, width: "100%", textAlign: "center" }}>
          <Typography variant="body1" gutterBottom>
            Identificación Oficial:
          </Typography>
          <img
            src={verificationData.idPhotoUrl}
            alt="Identificación Oficial"
            style={{ width: "100%", cursor: "pointer" }}
            onClick={() => handleZoom(verificationData.idPhotoUrl)}
          />
        </Box>
        <Box sx={{ mt: 3, width: "100%", textAlign: "center" }}>
          <Typography variant="body1" gutterBottom>
            Foto con Identificación:
          </Typography>
          <img
            src={verificationData.faceAndIdPhotoUrl}
            alt="Foto con Identificación"
            style={{ width: "100%", cursor: "pointer" }}
            onClick={() => handleZoom(verificationData.faceAndIdPhotoUrl)}
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleStatusChange("Approved")}
            sx={{ mr: 2 }}
          >
            Aprobar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleStatusChange("Rejected")}
          >
            Rechazar
          </Button>
        </Box>
      </Box>
      <Dialog open={!!zoomImage} onClose={handleCloseZoom}>
        <DialogTitle>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseZoom}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <img
            src={zoomImage}
            alt="Zoom"
            style={{ width: "100%", height: "auto" }}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default Verification;
