import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  Container,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { doc, getDoc, deleteDoc } from "firebase/firestore";
import { ref, listAll, deleteObject } from "firebase/storage";
import { db, storage } from "../../firebase";
import EspacioForm from "../Way/EspacioForm"; // Asegúrate de que la ruta sea correcta

const MySpace = () => {
  const { uid } = useParams();
  const [space, setSpace] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    const fetchSpace = async () => {
      try {
        const docRef = doc(db, "Ways", uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setSpace(docSnap.data());
        }
      } catch (error) {
        console.error("Error fetching space:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSpace();
  }, [uid]);

  const deleteImages = async () => {
    const storageRef = ref(storage, `ways/${uid}`);
    const listResults = await listAll(storageRef);

    const deletePromises = listResults.items.map((itemRef) =>
      deleteObject(itemRef)
    );

    await Promise.all(deletePromises);
  };

  const handleDelete = async () => {
    try {
      await deleteImages();
      await deleteDoc(doc(db, "Ways", uid));
      window.location.href = "/spacemanager"; // Redirige a la lista de espacios del usuario después de borrar
    } catch (error) {
      console.error("Error deleting space:", error);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!space) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <Typography variant="h6" color="text.secondary">
          Espacio no encontrado.
        </Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        {space.title}
      </Typography>
      {editMode ? (
        <EspacioForm space={space} />
      ) : (
        <Box>
          <Typography variant="body1" gutterBottom>
            Información del espacio:
          </Typography>
          <Typography variant="body2" gutterBottom>
            {JSON.stringify(space, null, 2)}
          </Typography>
          <Box sx={{ mt: 4 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setEditMode(true)}
              sx={{ mr: 2 }}
            >
              Editar Alojamiento
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpenDeleteDialog(true)}
              sx={{ mr: 2 }}
            >
              Borrar Alojamiento
            </Button>
            <Button
              variant="contained"
              color="info"
              component={Link}
              to={`/bookingmanager/${uid}`}
              sx={{ mr: 2 }}
            >
              Booking Manager
            </Button>
            <Button
              variant="contained"
              color="info"
              component={Link}
              to={`/messages/${uid}`}
            >
              Messages
            </Button>
          </Box>
        </Box>
      )}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Confirmar Borrado</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que quieres borrar este alojamiento y todas sus
            imágenes? Esta acción no se puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancelar</Button>
          <Button onClick={handleDelete} color="secondary">
            Borrar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default MySpace;
